import { DemandeTypes } from 'model/DemandeTypesEnum';
import axios from 'utils/axios';
import { GetLoanRequestDTO, PostLoanRequestDTO } from '../model/LoanRequestDTO';

export class LoanRequestService {

    get(id: number) {
        return axios.get(`/api/v1/demandes/pret/` + id)
            .then(response => {
                if (response.data && response.data.type === DemandeTypes.Loan.id) {
                    const result: GetLoanRequestDTO = response.data
                    return result
                } else {
                    // Cela ne devrait jamais arriver
                    throw new Error("Mauvais type de demande récupérée")
                }                
            })
            .catch(error => {
                console.log(error)
                throw error;
            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/demandes/pret/` + id)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    getAll(pageSize: any, itemOffset: any, sortedBy: any, filteredBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "statut-asc,dateCreation-desc"
        }
        let filtered="&FilteredBy="
        if (filteredBy) {
            filtered += filteredBy 
        }else{
            filtered = ""
        }
        return axios.get(`/api/v1/demandes/pret?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy+filtered)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });

    }
    create(data: PostLoanRequestDTO) {
        return axios.post(`/api/v1/demandes/pret`, data)
            .then((response: any) => {
                return response.data
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    update(id: number, data: PostLoanRequestDTO) {
        return axios.put(`/api/v1/demandes/pret/` + id, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    publish(id: number, data: PostLoanRequestDTO) {
        return axios.put(`/api/v1/demandes/pret/` + id + `/publish`, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    accept(id: number, data: PostLoanRequestDTO) {
        return axios.put(`/api/v1/demandes/pret/` + id + `/accept`, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    deny(id: number, data: PostLoanRequestDTO) {
        return axios.put(`/api/v1/demandes/pret/` + id + `/deny`, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    cancel(id: number, data: PostLoanRequestDTO) {
        return axios.put(`/api/v1/demandes/pret/` + id + `/cancel`, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }

} 