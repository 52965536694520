import { useEffect } from "react";

import {
    Card,
    CardHeader,
    CardBody
} from "@progress/kendo-react-layout";
import { useNavigate } from "react-router";

function LogoutView() {

    const navigate = useNavigate()
    useEffect(() => {
        function timeout(delay: number) {
            return new Promise(res => setTimeout(res, delay)).then(() => {
                navigate("/login")
            });
        }
        timeout(3000)
    }, [navigate])

    return (
        <div className="login">
            <img src="\assets\Siemens_Healthineers_logo.svg" alt="logo" />
            <div className="login-wrapper">
                <Card>
                    <CardHeader style={{ borderStyle: 'hidden' }}>
                        <h2>Vous êtes déconnecté</h2>
                    </CardHeader>
                    <CardBody>
                        <span>Vous avez été déconnecté ou votre session a expiré.</span>
                        <span>Vous allez être redirigé vers la page de connexion...</span>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default (LogoutView);

