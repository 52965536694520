import { PostUserDTO, UserDTO } from '../model/UserDTO';
import axios from 'utils/axios';
import { setAntiForgeryToken } from "store/slices/globalSlice";

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/auth"

function login() {
    window.location.replace(`${API_URL}/login`)
}

function devLogin(username: string, password: string) {
    return axios.post(`/api/v1/users/authenticate`, { username, password })
        .then(response => {
            return response.data
        });

}

function logout() {
    window.location.replace(`${API_URL}/logout`)
}

function getUserInfo(dispatch: any, token: string | null = null) {
    let config = undefined
    if (token) {
        config = {
            headers: { Authorization: `Bearer ${token}` },
        };

    }

    return axios.get(`/api/v1/users/getMyUserInfos`, config)
        .then(response => {
            if (response && response.data) {
                const user: UserDTO = response.data;
                const aft = response.headers['xsrf-token']
                dispatch(setAntiForgeryToken(aft))
                if (token)
                    user.token = token
                return user
            }
        })
}

function getAll(pageSize: any, itemOffset: any, sortedBy: any) {
    if (!pageSize) {
        pageSize = 10
    }
    if (!itemOffset) {
        itemOffset = 0
    }
    if (!sortedBy) {
        sortedBy = "statut-asc"
    }
    console.log(sortedBy);

    return axios.get(`/api/v1/users/getAll?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw (error)
        });
}
function postUser(data: PostUserDTO) {
    return axios.post(`/api/v1/users`, data)

}
function getUser(id: number) {
    return axios.get(`/api/v1/users/` + id)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw (error)
        });
}
function putUser(id: number, data: PostUserDTO) {
    return axios.put(`/api/v1/users/` + id, data)
        .catch((error: any) => {
            console.log(error)
            throw (error)
        });
}
function deleteUser(id: number) {
    return axios.delete(`/api/v1/users/` + id)
        .catch((error: any) => {
            console.log(error)
            throw (error)
        });
}

const AuthService = {
    login,
    devLogin,
    logout,
    getUserInfo,
    getAll,
    postUser,
    getUser,
    putUser,
    deleteUser
}

export default AuthService