import moment from "moment";
import { OccupationDTO } from "../../model/OccupationDTO";

export class ScheduleHelper {
    baseData: any = [];
    currentYear = new Date().getFullYear();
    displayDate = new Date();

    parseAdjust = (eventDate: any) => {
        const date = new Date(eventDate);
        date.setFullYear(this.currentYear);
        return date;
    };



}

enum OccupationColor {
    "#00000000",
    "#F9BF91",
    "#80CD9C",
    "#D1D1D1"
}

export function formatOccupation(occupation: OccupationDTO) {
    let baseData: any = null
    let title = "";
    switch (occupation.demande.type) {
        case 0:
            title = `Demande de Démonstration à ${occupation.demande.ville}`
            break;
        case 1:
            title = `Demande de Prêt à ${occupation.demande.ville}`
            break;
        case 2:
            title = `Demande d'Installation à ${occupation.demande.ville}`
            break;
        case 4:
            title = `Demande de Retour de Formation à ${occupation.demande.ville}`
            break;
        case 3:
        case 5:
            title = "Indisponible"
    }
    if (occupation.dateDeDebut === occupation.dateDeFin) {
        const end = new Date(occupation.dateDeFin)
        end.setHours(end.getHours() + 1)
        occupation.dateDeFin = moment(end).format('YYYY-MM-DDTHH:mm:ss')
    }
    baseData =
    {
        id: occupation.demande.id,
        start: new Date(occupation.dateDeDebut),
        startTimezone: null,
        end: new Date(occupation.dateDeFin),
        endTimezone: null,
        isAllDay: true,
        title: title,
        description: occupation.demande?.numero,
        recurrenceRule: "",
        recurrenceId: null,
        recurrenceExceptions: null,
        resourceId: occupation.ressource.id,
        color: OccupationColor[occupation.statut],
        occupation: occupation
    }
    return baseData
}