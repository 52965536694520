import axios from 'utils/axios';
import { CarrierDTO } from "../model/CarrierDTO"

export class CarrierService {
    getAll(pageSize: any, itemOffset: any, sortedBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "dateCreation-asc"
        }
        return axios.get(`/api/v1/transporteurs?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    getAllTrans() {
        return axios.get(`/api/v1/transporteurs`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    post(data: CarrierDTO) {
        return axios.post(`/api/v1/transporteurs`, data)
            .catch(error => {
                console.log(error)

            });
    }
    get(id: number) {
        return axios.get(`/api/v1/transporteurs/` + id)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    patch(id: number, data: CarrierDTO) {
        return axios.patch(`/api/v1/transporteurs/` + id, data)
            .catch((error: any) => {
                console.log(error)

            });
    }
    put(id: number, data: CarrierDTO) {
        return axios.put(`/api/v1/transporteurs/` + id, data)
            .catch((error: any) => {
                console.log(error)

            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/transporteurs/` + id)
            .catch((error: any) => {
                console.log(error)

            });
    }
} 