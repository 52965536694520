import { DemandeTypes } from 'model/DemandeTypesEnum';
import axios from 'utils/axios';
import { GetInstallRequestDTO, PostInstallRequestDTO } from '../model/InstallRequestDTO';

export class InstallRequestService {
    get(id: number) {
        return axios.get(`/api/v1/demandes/installation/` + id)
            .then(response => {
                if (response.data && response.data.type === DemandeTypes.Installation.id) {
                    const result: GetInstallRequestDTO = response.data
                    return result
                } else {
                    // Cela ne devrait jamais arriver
                    throw new Error("Mauvais type de demande récupérée")
                }
            })
            .catch(error => {
                console.log(error)
                throw error;
            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/demandes/installation/` + id)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    getAll(pageSize: any, itemOffset: any, sortedBy: any, filteredBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "statut-asc,dateCreation-asc"
        }
        let filtered = "&FilteredBy="
        if (filteredBy) {
            filtered += filteredBy
        } else {
            filtered = ""
        }
        return axios.get(`/api/v1/demandes/installation?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy + filtered)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });

    }
    create(data: any) {
        return axios.post(`/api/v1/demandes/installation`, data)
            .then((response: any) => {
                console.log(response);
                return response.data
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    update(id: number, data: PostInstallRequestDTO) {
        return axios.put(`/api/v1/demandes/installation/` + id, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    cancel(id: number, data: PostInstallRequestDTO) {
        return axios.put(`/api/v1/demandes/installation/` + id + `/cancel`, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }

} 