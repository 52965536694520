import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Form, Field, FormElement, FormRenderProps } from "@progress/kendo-react-form";

import { Button } from '@progress/kendo-react-buttons';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import { Spinner } from "components/Spinner/Spinner";
import FieldInput from "components/CustomFormFields/FieldInput";
import FieldComboBox from "components/CustomFormFields/FieldComboBox";
import { ModalityService } from "services/ModalityService";
import FieldDatePicker from "components/CustomFormFields/FieldDatePicker";
import FieldRadioGroup from "components/CustomFormFields/FieldRadioGroup";
import { GetToPostStakeholder, Stakeholder, StakeholderDTO } from "model/StakeholderDTO";
import { StakeholderService } from "services/StakeholderService";
import { ModalityDTO } from "model/ModalityDTO";
import { radioGroupValidator, requiredValidator } from "utils/FormValidators";
import { adjustForTimezone } from "utils/DateUtils";
import { useNotification } from "components/Notifications/NotificationProvider";
import { RootState, useAppDispatch } from "store";
import { connect } from "react-redux";
import { setLoading } from "store/slices/globalSlice";

const NAVIGATE_PATH = "/admin/intervenants"

const StakeholderFormView = (props: any) => {

    const equipmentFieldFormat = {
        tempPurpose: null,
        tempPurposeStart: null,
        tempPurposeEnd: null,
        purpose: 0,
        name: "",
        modality: null,
    }

    const purposes = [
        {
            label: "Démonstration/Prêt",
            value: 0,
        },
        {
            label: "Installation/Formation",
            value: 1,
        },
    ];

    const tempPurposes = [
        {
            label: "Aucune",
            value: null,
        },
        {
            label: "Démonstration/Prêt",
            value: 0,
        },
        {
            label: "Installation/Formation",
            value: 1,
        },
    ];

    const navigate = useNavigate();
    const notify = useNotification();
    const dispatch = useAppDispatch();
    let { id } = useParams();
    const [stakeholder, setStakeholder] = useState<StakeholderDTO>(new Stakeholder());
    const [init, setInit] = useState<{
        tempPurpose: number | null,
        tempPurposeStart: string | null,
        tempPurposeEnd: string | null,
        purpose: number,
        name: string,
        modality: ModalityDTO | null,
    }>(equipmentFieldFormat);
    const [modalities, setModalities] = useState<ModalityDTO[]>([]);

    useEffect(() => {
        function handleInit(): any {
            function fetchModality() {
                const modalityService = new ModalityService();
                modalityService.getAll().then((result: any) => {
                    setModalities(result)
                })
            }


            if (props.layout === "edit" && id) {
                const stakeholderService = new StakeholderService();
                stakeholderService.get(+id).then((data: StakeholderDTO) => {

                    if (data) {
                        console.log(data);

                        setStakeholder(data);
                        const res = {
                            tempPurpose: data.perimetreTemporaire,
                            tempPurposeStart: data.perimetreTemporaireDateDebut,
                            tempPurposeEnd: data.perimetreTemporaireDateFin,
                            purpose: data.perimetre,
                            name: data.nom,
                            modality: data.modalite,
                        }
                        console.log(res);

                        setInit(res)
                    }
                });
            }

            fetchModality()
        }
        handleInit();
    }, [id, props.layout])

    const handleSubmit = (dataItem: { [name: string]: any; }) => {
        if (dataItem.tempPurpose !== 0 && dataItem.tempPurpose !== 1) {
            dataItem.tempPurposeStart = null
            dataItem.tempPurposeEnd = null
        }



        let result = stakeholder;
        result.modalite = dataItem.modality
        result.nom = dataItem.name
        result.perimetre = dataItem.purpose
        result.perimetreTemporaire = dataItem.tempPurpose
        result.perimetreTemporaireDateDebut = dataItem.tempPurposeStart ? adjustForTimezone(new Date(dataItem.tempPurposeStart)).toISOString() : null
        result.perimetreTemporaireDateFin = dataItem.tempPurposeStart ? adjustForTimezone(new Date(dataItem.tempPurposeEnd)).toISOString() : null

        const stakeholderService = new StakeholderService();
        if (props.layout === "edit" && id) {
            dispatch(setLoading(true))
            stakeholderService.put(+id, GetToPostStakeholder(result)).then(() =>
                navigate(NAVIGATE_PATH)
            ).catch((error) => {
                notify({ message: error, type: "error" })
                dispatch(setLoading(false))
            });
        } else {
            dispatch(setLoading(true))
            stakeholderService.post(GetToPostStakeholder(result))
                .then(() => {
                    navigate(NAVIGATE_PATH)
                }).catch((error) => {
                    notify({ message: error, type: "error" })
                    dispatch(setLoading(false))
                });
        }
    };

    const buttonGroup = (formRenderProps: any) => {
        return (
            <>
                <Button
                    name="cancel"
                    type="button"
                    fillMode="outline"
                    onClick={() => { navigate(NAVIGATE_PATH) }}
                >
                    Retour
                </Button>
                <Button
                    name="submit"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    themeColor={"primary"}
                >
                    Enregistrer
                </Button>
            </>

        )
    }

    return (
        <>
            <Form
                initialValues={init}
                key={JSON.stringify(init)}
                onSubmit={handleSubmit}
                render={(formRenderProps: FormRenderProps) => (
                    <div className='wrapper'>
                        <FormElement>

                            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <span className="section-title">{props.layout === "edit" ? "Intervenant " + stakeholder.nom : "Ajouter un intervenant"}</span>
                                <div className="k-form-buttons"
                                    style={{ marginTop: "0" }}
                                >
                                    {buttonGroup(formRenderProps)}
                                </div>
                            </div>
                            <Card>
                                <div>

                                    <div className='form-section'>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"name"}
                                                label={"Nom de la Ressource"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"modality"}
                                                label={"Modalité"}
                                                required={true}
                                                id={"modality"}
                                                data={modalities}
                                                textField="nom"
                                                dataItemKey="id"
                                                component={FieldComboBox}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                        <StackLayout>
                                            <Field
                                                name={"purpose"}
                                                label={"Assignation"}
                                                data={purposes}
                                                required={true}
                                                component={FieldRadioGroup}
                                                validator={radioGroupValidator}
                                            />
                                        </StackLayout>
                                        <span className="k-form-separator"></span>
                                        <legend className={"k-form-legend"}>Assignation Temporaire</legend>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"tempPurpose"}
                                                label={"Assignation"}
                                                data={tempPurposes.filter((purpose: any) => { return purpose.value !== formRenderProps.valueGetter("purpose") })}
                                                required={true}
                                                component={FieldRadioGroup}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"tempPurposeStart"}
                                                id={"tempPurposeStart"}
                                                label={"Date de début"}
                                                disabled={formRenderProps.valueGetter("tempPurpose") === null ? true : false}
                                                required={formRenderProps.valueGetter("tempPurpose") === null ? false : true}
                                                min={new Date()}
                                                component={FieldDatePicker}
                                                validator={formRenderProps.valueGetter("tempPurpose") === null ? undefined : requiredValidator}
                                            />
                                            <Field
                                                name={"tempPurposeEnd"}
                                                id={"tempPurposeEnd"}
                                                label={"Date de fin"}
                                                disabled={formRenderProps.valueGetter("tempPurpose") === null ? true : false}
                                                required={formRenderProps.valueGetter("tempPurpose") === null ? false : true}
                                                min={formRenderProps.valueGetter("tempPurposeStart")}
                                                component={FieldDatePicker}
                                                validator={formRenderProps.valueGetter("tempPurpose") === null ? undefined : requiredValidator}
                                            />

                                        </StackLayout>
                                    </div>

                                </div>
                            </Card>
                        </FormElement>
                    </div>

                )
                }

            />
            {
                props.loading
                    ? <Spinner />
                    : null
            }
        </>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(StakeholderFormView);