import axios from 'utils/axios';
import { ConstraintDTO } from "../model/ConstraintDTO"

export class ConstraintService {
    getAll(pageSize: any, itemOffset: any, sortedBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "dateCreation-asc"
        }
        return axios.get(`/api/v1/contraintes-de-livraison?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    getAllConst() {
        return axios.get(`/api/v1/contraintes-de-livraison`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    post(data: ConstraintDTO) {
        return axios.post(`/api/v1/contraintes-de-livraison`, data)
            .catch(error => {
                console.log(error)

            });
    }
    get(id: number) {
        return axios.get(`/api/v1/contraintes-de-livraison/` + id)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    patch(id: number, data: ConstraintDTO) {
        return axios.patch(`/api/v1/contraintes-de-livraison/` + id, data)
            .catch((error: any) => {
                console.log(error)

            });
    }
    put(id: number, data: ConstraintDTO) {
        return axios.put(`/api/v1/contraintes-de-livraison/` + id, data)
            .catch((error: any) => {
                console.log(error)

            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/contraintes-de-livraison/` + id)
            .catch((error: any) => {
                console.log(error)

            });
    }
} 