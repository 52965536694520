import axios from 'utils/axios';

export class RequestService {

    getExcelExport(start: string, end: string) {
        return axios.get(`/api/v1/demandes/GetExportExcelDemandes?dateDebut=${start}&dateFin=${end}`)
            .then((result: any) => {
                if (result)
                    return result.data
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }

    getDocument(id: number) {
        return axios.get(`/api/v1/demandes/document/${id}`)
            .then((result: any) => {
                if (result)
                    return result.data
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }

} 