import { SchedulerViewItem, SchedulerViewItemProps } from "@progress/kendo-react-scheduler";

const CustomViewItem = (props: SchedulerViewItemProps) => {
    return (
        <SchedulerViewItem
            {...props}
            style={{
                ...props.style,
                height: "50px",
            }}
        />
    );
};
export default CustomViewItem;