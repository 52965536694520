import { Buffer } from 'buffer'
import { Button } from "@progress/kendo-react-buttons";
import prettyBytes from "pretty-bytes";
import { useNotification } from 'components/Notifications/NotificationProvider';
import { RequestService } from 'services/RequestService';
import { DocumentDTO } from 'model/DocumentDTO';

export const FileItem = (props: any) => {

    // let item: File;
    const { id, documentName, documentType } = props.dataItem;

    const notify = useNotification()

    // item = base64ToFile(props.dataItem)
    const item = { name: documentName, size: "Non renseigné" }
    function handleDelete() {
        props.deleteFile(props.dataItem)
    }

    function handleDownload() {
        if (id) {
            const requestService = new RequestService()
            requestService.getDocument(props.dataItem.id)
                .then((result) => {
                    if (result) {
                        downloadBase64File(result)
                    }
                })
                .catch((error) => {
                    notify({ message: error, type: "error" })
                })
        } else {
            downloadBase64File(props.dataItem)
        }
    }

    function downloadBase64File(documentRes: DocumentDTO) {
        const linkSource = `data:${documentRes.documentType};base64,${documentRes.documentData}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = documentRes.documentName;
        downloadLink.click();
    }

    return (
        <div key={item.name} className="row file-item">
            <button className='download-button' onClick={handleDownload} type={'button'}>
                <span className="file-name">{item.name}</span>
            </button>
            {/* <span className="file-size">{item.size}</span> */}
            {documentType === 4 || documentType === 5 || documentType === 6
                ?
                <Button
                    className="delete-file"
                    fillMode="flat"
                    onClick={handleDelete}
                >
                    <i className="icon-delete" />
                </Button>
                : null
            }
        </div>
    )
}
export const ExcelFileItem = (props: any) => {

    let item: File;
    const { documentData, documentName } = props.dataItem;
    const documentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    function base64ToFile(): File {
        const buff = Buffer.from(documentData, 'base64');
        const bstr = buff.toString('utf-8');
        let n = bstr.length;
        let dataArray = new Uint8Array(n);


        while (n--) {
            dataArray[n] = bstr.charCodeAt(n);
        }

        return new File([dataArray], documentName, { type: documentType });
    }
    item = base64ToFile()

    function handleDelete() {
        props.deleteFile(props.dataItem)
    }

    function downloadBase64File() {
        const linkSource = `data:${documentType};base64,${documentData}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = documentName;
        downloadLink.click();
    }
    return (
        <div key={item.name} className="row file-item">
            <button className='download-button' onClick={downloadBase64File} type={'button'}>
                <span className="file-name">{item.name}</span>
            </button>
            <span className="file-size">{`${prettyBytes(item.size)}`}</span>
            {props.deleteFile ?
                <Button
                    className="delete-file"
                    fillMode="flat"
                    onClick={handleDelete}
                >
                    <i className="icon-delete" />
                </Button>
                : null}
        </div>
    )
}