export interface ModalityDTO {
    nom: string,
    imputations:string[]
    id: number,
}

export interface IModality extends ModalityDTO {
}

export class Modality implements IModality {
    nom: string = "";
    imputations:string[] = []
    id: number = 0;
}