import React, {createContext, useContext, useReducer, useEffect} from "react";
import {v4} from "uuid";
import Notifications from "./Notifications";
import {
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { setNotificationFunction } from "utils/axios/interceptors";
import { Fade } from "@progress/kendo-react-animation";

const NotificationContext = createContext();

const NotificationProvider = (props) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "ADD_NOTIFICATION":
        return [...state, {...action.payload}];
      case "REMOVE_NOTIFICATION":
        return state.filter(el => el.id !== action.id);
      default:
        return state
    }
  }, []);

  useEffect(() => {
    setNotificationFunction((props) => {
      dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          id: v4(),
          ...props
        }
      })
    });
  }, [dispatch]);

  return(
    <NotificationContext.Provider value={dispatch}>
      <div className={"notification-wrapper"}>
      <NotificationGroup
        style={{
          right: 0,
          bottom: 0,
          alignItems: "flex-start",
          flexWrap: "wrap-reverse",
        }}
      >
        
      <Fade enter={true} exit={true}>
        {state.map((note) => {
          return <Notifications dispatch={dispatch} key={note.id} {...note} />
        })}
        </Fade>
        </NotificationGroup>
      </div>
      {props.children}
    </NotificationContext.Provider>
  )
};

export const useNotification = () => {
  const dispatch = useContext(NotificationContext);

  return (props) => {
    dispatch({
      type: "ADD_NOTIFICATION",
      payload: {
        id: v4(),
        ...props
      }
    })
  }
};

export default NotificationProvider;