import { Request, RequestDTO } from "./RequestDTO";
import { StakeholderDTO } from "./StakeholderDTO";

export interface GetVacationRequestDTO extends RequestDTO {
  intervenants: StakeholderDTO[],
  dateDebut: string,
  dateFin: string,

}

export interface PostVacationRequestDTO {
  commentaire: string,
  intervenantIds: number[],
  dateDebut: string,
  dateFin: string,
}

export function GetToPostVacation(data: GetVacationRequestDTO): PostVacationRequestDTO {

  const format: PostVacationRequestDTO = {
    commentaire: data.commentaire,
    dateDebut: data.dateDebut,
    dateFin: data.dateFin,
    intervenantIds: data.intervenants?.map((item: any) => item.id),
  }
  return format;
}

export default class VacationRequest extends Request {
  dateDebut = new Date().toDateString()
  dateFin = new Date().toDateString()
  intervenants = []
  commentaire = ""

  constructor() {
    super();
    this.type = 1
  }
}