import { isWithinRange } from "utils/DateUtils";
import { Modality } from "./ModalityDTO";
import { PostResourceDTO, ResourceDTO } from "./ResourceDTO";


export interface StakeholderDTO extends ResourceDTO {
  perimetre: number,
  perimetreTemporaire: number,
  perimetreTemporaireDateDebut: string | null,
  perimetreTemporaireDateFin: string | null,
}

export interface PostStakeholderDTO extends PostResourceDTO {
  perimetre: number,
  perimetreTemporaire: number,
  perimetreTemporaireDateDebut: string | null,
  perimetreTemporaireDateFin: string | null,
}

export function GetToPostStakeholder(data: StakeholderDTO): PostStakeholderDTO {
  const format: PostStakeholderDTO = {
    perimetre: data.perimetre,
    nom: data.nom,
    modaliteId: data.modalite.id,
    perimetreTemporaire: data.perimetreTemporaire,
    perimetreTemporaireDateDebut: data.perimetreTemporaireDateDebut,
    perimetreTemporaireDateFin: data.perimetreTemporaireDateFin
  }
  return format
}

export interface IStakeholder extends StakeholderDTO {
}

export class Stakeholder implements IStakeholder {
  perimetreTemporaire = 0
  perimetreTemporaireDateDebut = "";
  perimetreTemporaireDateFin = "";

  perimetre: number = 0;
  nom: string = "";
  modalite = new Modality();
  id: number = 0;
  constructor(
    id: number = 0,
    modalite: Modality = new Modality(),
    nom: string = "",
    perimetre: number = 0,
    perimetreTemporaire: number = 0,
    perimetreTemporaireDateDebut: string = "",
    perimetreTemporaireDateFin: string = "",
  ) {
    this.perimetreTemporaire = perimetreTemporaire;
    this.perimetreTemporaireDateDebut = perimetreTemporaireDateDebut;
    this.perimetreTemporaireDateFin = perimetreTemporaireDateFin;
    this.perimetre = perimetre;
    this.nom = nom;
    this.modalite = modalite;
    this.id = id;

  };
  getResourceScope(dateDebut: Date | null, dateFin: Date | null): string {
    switch (this.perimetre) {
      case 0:
        if (dateDebut !== null && dateFin !== null && this.perimetreTemporaire && isWithinRange(dateDebut, dateFin, this.perimetreTemporaireDateDebut, this.perimetreTemporaireDateFin)) {
          return "InstallForma"
        }
        return "DemoPret";
      case 1:
        if (dateDebut !== null && dateFin !== null && this.perimetreTemporaire && isWithinRange(dateDebut, dateFin, this.perimetreTemporaireDateDebut, this.perimetreTemporaireDateFin)) {
          return "DemoPret";
        }
        return "InstallForma";
      default:
        return "";
    }
  }
  getResourcePurpose() {
    return this.perimetreTemporaire ? this.perimetreTemporaire : this.perimetre
  }
}