import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useState } from "react";

interface EditButtonProps extends GridCellProps {
    action: (data: any) => void;
}
export const EditButton = (props: EditButtonProps) => {
    const data = props.dataItem;
    return (            
        <Button fillMode="flat" className="button-edit action-button" onClick={() => props.action(data)}>
        <i className="icon-edit" title="edit"></i>
        </Button>
    );
}
interface DuplicateButtonProps extends GridCellProps {
    action: (data: any) => void;
}
export const DuplicateButton = (props: DuplicateButtonProps) => {
    const data = props.dataItem;
    return (            
        <Button fillMode="flat" className="button-duplicate action-button" onClick={() => props.action(data)}>
                <img width="24px" height="24px" src="/assets/duplicate.png" alt="duplication" title="duplicate"></img>
            </Button>
    );
}
interface DeleteButtonProps extends GridCellProps {
    action: (data: any) => void;
    message: string;
}
export const DeleteButton = (props: DeleteButtonProps ) => {
    const data = props.dataItem;
    const [visible, setVisible] = useState<boolean>(false);
    const toggleDialog = () => {
        setVisible(!visible);
    };
    function handleDelete() {
        props.action(data)
        toggleDialog();
    }

    return (           
        <> 
            <Button fillMode="flat" className="button-delete action-button" onClick={toggleDialog}>
                <i className="icon-delete" ></i>
            </Button>
            {visible && (
                <Dialog title={"Confirmez"} onClose={toggleDialog}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        {props.message}
                    </p>
                    <DialogActionsBar layout="end">
                        <Button fillMode="outline" onClick={toggleDialog}>
                            Non
                        </Button>
                        <Button themeColor="primary" onClick={handleDelete}>
                            Oui
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </>
    );
}

interface CancelButtonProps extends GridCellProps {
    action: (data: any) => void;
    message: string;
}
export const CancelButton = (props: CancelButtonProps) => {
    const data = props.dataItem;
    const [visible, setVisible] = useState<boolean>(false);
    const toggleDialog = () => {
        setVisible(!visible);
    };
    function handleCancel() {
        props.action(data)
        toggleDialog();
    }

    return (           
        <> 
            <Button fillMode="flat" className="button-delete action-button" onClick={toggleDialog}>
                <img width="24px" height="24px" src="/assets/abort.png" alt="annulation" title="cancel"></img>
            </Button>
            {visible && (
                <Dialog title={"Confirmez"} onClose={toggleDialog}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        {props.message}
                    </p>
                    <DialogActionsBar layout="end">
                        <Button fillMode="outline" onClick={toggleDialog}>
                            Non
                        </Button>
                        <Button themeColor="primary" onClick={handleCancel}>
                            Oui
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </>
    );
}
