export interface DocumentDTO {
    documentName: string,
    documentData: string,
    documentType: number,
    id: number | null
}

export interface PostDocumentDTO {
    documentName: string,
    documentDataAsByteArray: string,
    documentType: number,
    id: number | null
}
export function GetToPostDocument(data: DocumentDTO): PostDocumentDTO {
    const format: PostDocumentDTO = {
        documentName: data.documentName,
        documentDataAsByteArray: data.documentData,
        documentType: data.documentType,
        id: data.id || null
    }

    return format
}

export interface IDocument extends DocumentDTO {
}

export class Document implements IDocument {
    documentName = "";
    documentData = "";
    documentType = 0;
    id = null;

}