import { Button } from "@progress/kendo-react-buttons";
import { Card } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";

import { DeleteButton, EditButton } from "components/CustomCells/ActionCell";
import { StakeholderService } from "services/StakeholderService";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { connect } from "react-redux";
import { RootState } from "store";
import { useNotification } from "components/Notifications/NotificationProvider";
import moment from "moment";

const StakeholderGridView = (props: any) => {
  const navigate = useNavigate();
  const notify = useNotification();
  const initialSort: SortDescriptor[] = [
    {
      field: "nom",
      dir: "asc",
    },
  ];

  const initialPageState = {
    skip: 0,
    take: 10,
    sort: initialSort,
    buttonCount: 10,
    info: true,
    pageSizes: true,
    previousNext: true,
    responsive: true,
  };
  useEffect(() => {
    if (!(props.user && props.user.role === "GL")) {
      navigate("/");
    }
  }, [props.user, navigate]);

  const initialClassesState = {
    content: [],
    pageNumber: 1,
    pageSize: 10,
    totalElements: 0,
    totalPages: 1,
  };

  const ActionsCell = (props: GridCellProps) => (
    <td>
      <EditButton {...props} action={handleEdit} />
      <DeleteButton
        {...props}
        action={handleDelete}
        message={`Êtes-vous sûr de vouloir supprimer l'intervenant ${props.dataItem.nom} ?`}
      />
    </td>
  );
  const IntAssignationCell = (props: GridCellProps) => {
    const value = props.dataItem.perimetre;
    let result = "";
    if (value !== null || value !== undefined) {
      if (value === 0) {
        result = "Démonstration/Prêt";
      } else if (value === 1) {
        result = "Installation/Formation";
      }
    }
    return (
      <td>
        <span className="status-cell">{result}</span>
      </td>
    );
  };
  const TempAssignationCell = (props: GridCellProps) => {
    const value = props.dataItem.perimetreTemporaire;
    let result = "Aucune";
    if (value !== null || value !== undefined) {
      if (value === 0 || value === 1) {
        result =
          moment(new Date(props.dataItem.perimetreTemporaireDateDebut)).format(
            "D MMM y"
          ) +
          " - " +
          moment(new Date(props.dataItem.perimetreTemporaireDateFin)).format(
            "D MMM y"
          );
      }
    }

    return (
      <td>
        <span className="status-cell">{result}</span>
      </td>
    );
  };
  const [pageState, setPageState] = useState(initialPageState);
  const [title, setTitle] = useState("");
  const [addTitle, setAddTitle] = useState("");
  const [classes, setClasses] = useState(initialClassesState);

  let { skip, take, sort } = pageState;

  useEffect(() => {
    const { skip, take, sort } = pageState;
    getClasses(take, skip, sort);
  }, [pageState]);

  useEffect(() => {
    setTitle("Gestion des Intervenants");
    setAddTitle("Ajouter un intervenant");
  }, []);

  async function handleDelete(data: any) {
    const stakeholderService = new StakeholderService();
    await stakeholderService
      .delete(data.id)
      .then(() => getClasses(take, skip, sort))
      .catch((error: any) => {
        notify({ message: error, type: "error" });
      });
  }
  async function handleEdit(data: any) {
    navigate(`/admin/intervenants/edit/${data.id}`);
  }

  function handleNew() {
    navigate("/admin/intervenants/create");
  }
  const handlePageChange = (event: GridDataStateChangeEvent) => {
    const { skip, take } = event.dataState;
    setPageState({ ...pageState, skip: skip!, take: take! });
  };

  function handleSortChange(event: GridSortChangeEvent) {
    const sort = event.sort;
    setPageState({ ...pageState, sort: sort });
  }

  function getClasses(
    pageSize: number | null = null,
    itemOffset: number | null = null,
    sortDesciptor: SortDescriptor[] | null = null
  ) {
    const sortedBy = sortDesciptor
      ?.map((item: SortDescriptor) => item.field + "-" + item.dir)
      .join(",");
    console.log(sortedBy);
    const stakeholderService = new StakeholderService();
    stakeholderService.getAll(pageSize, itemOffset, sortedBy).then((result) => {
      console.log(result);
      setClasses(result);
    });
  }

  return (
    <div className="wrapper">
      <div
        className="row"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <span className="section-title">{title}</span>

        <Button themeColor={"primary"} onClick={handleNew}>
          {addTitle}
        </Button>
      </div>
      <Card>
        {classes.content.length ? (
          <Grid
            data={orderBy(classes.content, sort)}
            sortable={true}
            sort={sort}
            onSortChange={handleSortChange}
            skip={skip}
            take={take}
            onDataStateChange={handlePageChange}
            pageable={true}
            total={classes.totalElements}
            pageSize={10}
          >
            <Column key="nam" title="Nom" field="nom" />,
            <Column key="mod" title="Modalité" field="modalite.nom" />,
            <Column
              key="pps"
              title="Assignation"
              field="perimetre"
              cell={IntAssignationCell}
            />
            ,
            <Column
              key="pps"
              title="Assignation Temporaire"
              field="perimetreTemporaire"
              cell={TempAssignationCell}
            />
            ,
            <Column key="act" title="Actions" width={110} cell={ActionsCell} />
          </Grid>
        ) : (
          <div>Aucuns objets trouvés</div>
        )}
      </Card>
    </div>
  );
};
const mapStateToProps = function (state: RootState) {
  return {
    user: state.user,
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
  };
};
export default connect(mapStateToProps)(StakeholderGridView);
