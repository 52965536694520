import { Button } from "@progress/kendo-react-buttons";
import { Card } from "@progress/kendo-react-layout";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    Grid,
    GridCellProps,
    GridColumn as Column,
    GridDataStateChangeEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";

import { CarrierService } from "services/CarrierService";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Carrier, CarrierDTO } from "model/CarrierDTO";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { connect } from "react-redux";
import { RootState } from "store";
import { useNotification } from "components/Notifications/NotificationProvider";
import CarrierFormView from "./CarrierFormView";
import { DeleteButton, EditButton } from "components/CustomCells/ActionCell";

const CarrierGridView = (props: any) => {
    const onEdit = (item: CarrierDTO) => {
        setEditedItem(item);
        setEditVisible(true);
    };
    const ActionsCell = (props: GridCellProps) => (
        <td>
            <EditButton {...props} action={onEdit} />
            <DeleteButton {...props} action={deleteItem} message={`Êtes-vous sûr de vouloir supprimer la contrainte ${props.dataItem.nom} ?`}/>
        </td>
    );
    const navigate = useNavigate();
    const notify = useNotification();
    const initialSort: SortDescriptor[] = [
        {
            field: "nom",
            dir: "asc",
        },
    ];

    const [editVisible, setEditVisible] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [editedItem, setEditedItem] = useState<CarrierDTO>(new Carrier());
    const initialPageState = {
        skip: 0,
        take: 10,
        sort: initialSort,
        buttonCount: 10,
        info: true,
        pageSizes: true,
        previousNext: true,
        responsive: true,
    };
    const [pageState, setPageState] = useState(initialPageState);
    let { skip, take, sort } = pageState;

    const [title, setTitle] = useState("");
    const [addTitle, setAddTitle] = useState("");
    const initialClassesState = {
        content: [],
        pageNumber: 1,
        pageSize: 10,
        totalElements: 0,
        totalPages: 1
    };
    const [items, setItems] = useState(initialClassesState)

    useEffect(() => {
        if (!(props.user && props.user.role === "GL")) {
            navigate("/")
        }
    }, [props.user, navigate])

    const getItems = useCallback((pageSize: number | null = null, itemOffset: number | null = null, sortDesciptor: SortDescriptor[] | null = null) => {
        const sortedBy = sortDesciptor?.map((item: SortDescriptor) => item.field + "-" + item.dir).join(",")
        console.log(sortedBy);
        const carrierService = new CarrierService()
        carrierService.getAll(pageSize, itemOffset, sortedBy)
            .then(result => {
                console.log(result);
                const data = {
                    content: result,
                    pageNumber: 1,
                    pageSize: 10,
                    totalElements: result.length,
                    totalPages: 1
                }

                setItems(data)
            });
    },[])


    useEffect(() => {
        const { skip, take, sort } = pageState;
        getItems(take, skip, sort)
    }, [pageState, getItems])

    useEffect((() => {
        setTitle("Gestion des transporteurs")
        setAddTitle("Ajouter un transporteur")
    }), [])

    async function saveItem(item: CarrierDTO) {
        const carrierService = new CarrierService();
        if(item.id === 0) {
            carrierService.post(item).then(() => {
                setEditVisible(false);
                getItems(take, skip, sort)
            })
            .catch((error: any) => {
                notify({ message: error, type: "error" })
            });
        } else {
            carrierService.put(item.id, item)
            .then(() => {
                setEditVisible(false);
                getItems(take, skip, sort)
            })
            .catch((error: any) => {
                notify({ message: error, type: "error" })
            });
        }
    }

    async function deleteItem(item: CarrierDTO) {
        const carrierService = new CarrierService();
        await carrierService.delete(item.id)
            .then(() => getItems(take, skip, sort))
            .catch((error: any) => {
                notify({ message: error, type: "error" })
            });
    }

    const onPageChange = (event: GridDataStateChangeEvent) => {
        const { skip, take } = event.dataState;
        setPageState({ ...pageState, skip: skip!, take: take! });

    };

    const onSortChange = (event: GridSortChangeEvent) => {
        const sort = event.sort
        setPageState({ ...pageState, sort: sort });
    }

    const onCancelDelete = (item: CarrierDTO) => {
        setDeleteVisible(false);
    };

    const onAcceptDelete = (item: CarrierDTO) => {
        deleteItem(item)
        setDeleteVisible(false);
    }


    function onNewItem() {
        setEditVisible(true)
        setEditedItem(new Carrier());
    }

    return (
        <div className='wrapper'>
            {editVisible && (
                <CarrierFormView
                    onCancel={() => setEditVisible(false)}
                    onSave={saveItem}
                    item={editedItem}
                />
            )}
            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <span className="section-title">{title}</span>

                <Button
                    themeColor={"primary"}
                    onClick={onNewItem}
                >
                    {addTitle}
                </Button>
            </div>
            <Card>
                {items.content.length
                    ?
                    (<><Grid
                        data={orderBy(items.content, sort).slice(skip, take + skip)}

                        sortable={true}
                        sort={sort}
                        onSortChange={onSortChange}
                        skip={skip}
                        take={take}
                        onDataStateChange={onPageChange}
                        pageable={true}
                        total={items.content.length}
                        pageSize={10}
                    >
                        <Column key="nam" title="Transporteur" field="nom" />,
                        <Column key="nam" title="Email" field="email" />,
                        <Column key="act" title="Actions" width={110} cell={ActionsCell} />
                    </Grid>
                    {deleteVisible && (
                        <Dialog title={"Confirmez"} onClose={() => onCancelDelete(editedItem)}>
                            <p style={{ margin: "25px", textAlign: "center" }}>
                                {"Êtes-vous sûr de vouloir supprimer le transporteur " + editedItem.nom + " ?"}
                            </p>
                            <DialogActionsBar layout="end">
                                <Button fillMode="outline"
                                    onClick={() => onCancelDelete(editedItem)}
                                >
                                    Non
                                </Button>
                                <Button themeColor="primary"
                                    onClick={() =>onAcceptDelete(editedItem)}
                                >
                                    Oui
                                </Button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                    </>)
                    :
                    <div>Aucuns transporteurs trouvés</div>
                }
            </Card>
        </div >
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
    }
}
export default (connect(mapStateToProps)(CarrierGridView));