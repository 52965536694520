import { RootState } from "store";
import { connect } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";


const RequestErrorPopup = (props: any) => {


    return (
        <>
            <Popup anchor={props.anchor} className="popup-real" show={props.visible} popupClass={"popup-error"}>
                <span className="popup-error-title row">
                    <i className="icon-warning" />
                    Impossible d'ajouter la demande
                    <span className="spacer" />
                    <i className="icon-close" onClick={props.togglePopupError} />
                </span>
                <span className="popup-error-subtitle" >les ressources sélectionnées sont déjà réservées dans les demandes suivantes : </span>
                {props.bookedError.map((request: any) => {
                    return (<div key={request.DemandeId}>
                        <span>{"- "}<b>{request.Ressource.Nom}</b>{` : ${request.Demande.Numero}, du ${new Date(request.DateDeDebut).toLocaleDateString("fr-FR", { day: 'numeric', month: 'long', year: 'numeric' })} au ${new Date(request.DateDeFin).toLocaleDateString("fr-FR", { day: 'numeric', month: 'long', year: 'numeric' })}`}</span>

                    </div>)
                })}

            </Popup>
        </>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(RequestErrorPopup);