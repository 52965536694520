import { Carrier, CarrierDTO } from "./CarrierDTO";
import { ConstraintDTO } from "./ConstraintDTO";
import { DocumentDTO, GetToPostDocument, PostDocumentDTO } from "./DocumentDTO";
import { Modality, ModalityDTO } from "./ModalityDTO";
import { Request, RequestDTO } from "./RequestDTO";
import { StakeholderDTO } from "./StakeholderDTO";

export interface GetInstallRequestDTO extends RequestDTO {
  intervenants: StakeholderDTO[],
  client: string,
  adresseDeLivraison: {
    rue: string,
    codePostal: string,
    ville: string,
  },
  contrainteDeLivraison: ConstraintDTO | null,
  contact: {
    nom: string,
    service: string,
    fonction: string,
    telephone: string,
  },
  transporteur: CarrierDTO,
  dateDeLivraison: string,
  commentaire: string,
  modalite: ModalityDTO,
  dateApplication1: string,
  dateApplication2: string | null,
  dateApplication3: string | null,
  designationEquipement: string,
  numeroSerieEquipement: string,
  budgetDeFormation: number,
  dateDeMiseEnService: string,
  commentaireTransporteur: string,
  numeroCommandeFournisseur: string,
}

export interface PostInstallRequestDTO {
  numero: string,
  commentaire: string,
  modaliteId: number,
  adresseDeLivraison: {
    rue: string,
    codePostal: string,
    ville: string,
  },
  statut?: number
  contrainteDeLivraisonId: number | null,
  dateDeLivraison: string,
  client: string,
  contact: {
    nom: string,
    service: string,
    fonction: string,
    telephone: string,
  },
  transporteurId: number,
  intervenantIds: number[],
  dateApplication1: string,
  dateApplication2: string | null,
  dateApplication3: string | null,
  designationEquipement: string,
  numeroSerieEquipement: string,
  budgetDeFormation: number,
  dateDeMiseEnService: string,
  commentaireTransporteur: string,
  numeroCommandeFournisseur: string,
  documents: PostDocumentDTO[]
}

export function GetToPostInstall(data: GetInstallRequestDTO): PostInstallRequestDTO {
  const format: PostInstallRequestDTO = {
    numero: data.numero,
    commentaire: data.commentaire,
    modaliteId: data.modalite.id,
    adresseDeLivraison: {
      rue: data.adresseDeLivraison.rue,
      codePostal: data.adresseDeLivraison.codePostal,
      ville: data.adresseDeLivraison.ville
    },
    contrainteDeLivraisonId: data.contrainteDeLivraison?.id || null,
    dateDeLivraison: data.dateDeLivraison,
    client: data.client,
    contact: {
      nom: data.contact.nom,
      service: data.contact.service,
      fonction: data.contact.fonction,
      telephone: data.contact.telephone
    },
    statut: data.statut,
    transporteurId: data.transporteur.id,
    intervenantIds: data.intervenants?.map((item: any) => item.id),
    dateApplication1: data.dateApplication1,
    dateApplication2: data.dateApplication2,
    dateApplication3: data.dateApplication3,
    designationEquipement: data.designationEquipement,
    numeroSerieEquipement: data.numeroSerieEquipement,
    budgetDeFormation: data.budgetDeFormation,
    dateDeMiseEnService: data.dateDeMiseEnService,
    commentaireTransporteur: data.commentaireTransporteur,
    numeroCommandeFournisseur: data.numeroCommandeFournisseur,
    documents: data.demandeDocuments.map((item: DocumentDTO) => GetToPostDocument(item))
  }
  return format;
}

export default class InstallRequest extends Request {
  modalite = new Modality()

  adresseDeLivraison: { rue: string; codePostal: string; ville: string } = {
    rue: "",
    codePostal: "",
    ville: ""
  }
  contrainteDeLivraison = null
  dateDeLivraison: string = new Date().toDateString()
  client: string = ""
  contact: { nom: string; service: string; fonction: string; telephone: string } = {
    nom: "",
    service: "",
    fonction: "",
    telephone: ""
  }
  transporteur = new Carrier()
  intervenants = []
  dateApplication1 = new Date().toDateString()
  dateApplication2 = ""
  dateApplication3 = ""
  designationEquipement = ""
  numeroSerieEquipement = ""
  budgetDeFormation = 0
  dateDeMiseEnService = new Date().toDateString()
  commentaireTransporteur = ""
  numeroCommandeFournisseur = ""

  demandeDocuments = []

  constructor() {
    super();
    this.type = 2
  }
}