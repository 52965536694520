import {
  Notification
} from "@progress/kendo-react-notification";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";


const Notifications = (props: any) => {
  const { type } = props;
  let message = ""
  let code = null
  const location = useLocation()

  const [loc] = useState(location.pathname)


  useEffect(() => {
    if (loc !== location.pathname) {
      handleCloseNotification()
    }
  }, [location])

  setTimeout(() => {
    handleCloseNotification();
  }, 10000);



  if (props.message.request) {
    const { request } = props.message
    if (request.response) {
      try {
        message = JSON.parse(request.response).message || JSON.parse(request.response).title
      } catch {
        message = request.response
      }
    }
    if (request.status) {
      code = request.status
    } else if (props.message.response && props.message.response.status) {
      code = props.message.response.status
    }
  } else {
    message = props.message.message || props.message
  }

  const handleCloseNotification = () => {
    props.dispatch({
      type: "REMOVE_NOTIFICATION",
      id: props.id
    });
  };

  return (
    <>
      {code !== 401 &&
        <Notification
          type={{
            style: type,
            icon: true,
          }}
          closable={true}
          onClose={handleCloseNotification}
        >
          <span>{`${message} ${code ? `(${code})` : ""}`}</span>
        </Notification>
      }
    </>
  );
};

export default Notifications;