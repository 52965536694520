export interface CarrierDTO {
    nom: string,
    email: string
    id: number, 
		defaultDemandeType: number,

}

export interface ICarrier extends CarrierDTO {
}

export class Carrier implements ICarrier {
    nom: string = "";
    email: string = "";
    id: number = 0;
		defaultDemandeType: number = -1;
}