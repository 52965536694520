import axios from 'utils/axios';
import { MAX_PAGE_SIZE } from 'services/Constantes';

export class OccupationService {
    getStakeholders(dateDeDebut: string, dateDeFin: string, filters: any) {
        let filtersString = "&filteredBy="
        if (filters) {
            if (filters.modality) {
                filtersString += `ressourceModaliteId|${filters.modality.id},`;
            }
            if (filters.requestType) {
                filtersString += `demandeType|${filters.requestType.id},`;
            }
            if (filters.requestStatus) {
                filtersString += `statut|${filters.requestStatus.id},`;
            }
            if (filters.equipment) {
                filtersString += `ressourceId|${filters.equipment.id},`;
            }
            if (filters.perimetre) {
                filtersString += `perimetre|${filters.perimetre},`;
            }
            if (filtersString.slice(-1) === ",") {
                filtersString = filtersString.slice(0, -1)
            }
        } else {
            filtersString = ""
        }

        return axios.get(`/api/v1/occupations/intervenants?PageSize=${MAX_PAGE_SIZE}&MaxPageSize=${MAX_PAGE_SIZE}&dateDebut=${dateDeDebut}&dateFin=${dateDeFin}${filtersString}`)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error)
            });
    }

    getEquipments(dateDeDebut: string, dateDeFin: string, filters: any) {
        let filtersString = "&filteredBy="
        if (filters) {
            if (filters.modality) {
                filtersString += `ressourceModaliteId|${filters.modality.id},`;
            }
            if (filters.requestType) {
                filtersString += `demandeType|${filters.requestType.id},`;
            }
            if (filters.requestStatus) {
                filtersString += `statut|${filters.requestStatus.id},`;
            }
            if (filters.equipment) {
                filtersString += `ressourceId|${filters.equipment.id},`;
            }
            if (filters.finalite) {
                filtersString += `finalite|${filters.finalite},`;
            }
            if (filtersString.slice(-1) === ",") {
                filtersString = filtersString.slice(0, -1)
            }
        } else {
            filtersString = ""
        }

        return axios.get(`/api/v1/occupations/equipements?PageSize=${MAX_PAGE_SIZE}&MaxPageSize=${MAX_PAGE_SIZE}&dateDebut=${dateDeDebut}&dateFin=${dateDeFin}${filtersString}`)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error)
            });
    }
    get(id: number) {
        return axios.get(`/api/v1/occupations/` + id)
            .catch((error: any) => {
                console.log(error)
            });
    }
    getAllBetween(dateDeDebut: string, dateDeFin: string, filters: any, type: string) {
        switch (type) {
            case "equipment":
                return this.getEquipments(dateDeDebut, dateDeFin, filters)
            case "stakeholder":
                return this.getStakeholders(dateDeDebut, dateDeFin, filters)
            default:
                return null;
        }
    }
} 