import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Upload } from "@progress/kendo-react-upload";
import { ListView } from "@progress/kendo-react-listview";
import { FileItem } from "./FileItem";

const FieldUpload = (fieldRenderProps: FieldRenderProps) => {

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        valid,
        // The input props of the Field.
        value,
        id,
        multiple,
        label,
        onChange,
        onFocus,
        onBlur,
        ...others
    } = fieldRenderProps;

    
    const showValidationMessage: string | false | null =
        touched && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const onChangeHandler = (event: any) => {        
        fieldRenderProps.onChange({ value: event.newState });
    };
    const onRemoveHandler = (event: any) => {
        fieldRenderProps.onChange({ value: event.newState });
    };

    const CustomFileItem = (props:any) => (
        <FileItem
            {...props}
            deleteFile={others.deleteFile}
        />
    );
        
    return (
        <FieldWrapper>
            <div onFocus={onFocus} onBlur={onBlur} className={others.required?"required":""}>
                <Upload
                    multiple={multiple}
                    onAdd={onChangeHandler}
                    onRemove={onRemoveHandler}
                    autoUpload={false}
                    showActionButtons={false}
                    showFileList={false}
                    restrictions={{
                        allowedExtensions: [".pdf"],
                      }}
                />
                <ListView
                    data={others.data}
                    item={CustomFileItem}
                />
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>

        </FieldWrapper>
    );

}

export default FieldUpload