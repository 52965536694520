import { Button } from '@progress/kendo-react-buttons';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { PDFExport } from '@progress/kendo-react-pdf';
import { DateHeaderCellProps, Scheduler, SchedulerItem, SchedulerItemProps, SchedulerSlot, SchedulerSlotProps, SchedulerViewItem, SchedulerViewItemProps, WeekView } from '@progress/kendo-react-scheduler';
import moment from 'moment';
import { useEffect, useRef, useState } from "react";
import { OccupationDTO } from '../../model/OccupationDTO';
import { OccupationService } from 'services/OccupationService';
import { Spinner } from 'components/Spinner/Spinner';
import { formatOccupation } from './ScheduleHelper';
import { useNotification } from 'components/Notifications/NotificationProvider';


export const CustomViewItem = (props: SchedulerViewItemProps) => {
    return (
        <SchedulerViewItem
            {...props}
            style={{
                ...props.style,
                height: "50px",
            }}
        />
    );
};
const CustomSlot = (props: SchedulerSlotProps) => {
    const resource: any = props.group.resources[0]
    return (
        <SchedulerSlot
            {...props}
            className={resource.dataType}
            style={{
                ...props.style,
                height: "34px",
            }}
        />
    )
};
export const CustomDateHeader = (props: DateHeaderCellProps) => {
    let options1: Intl.DateTimeFormatOptions = { weekday: "long" };
    let options2: Intl.DateTimeFormatOptions = { day: "numeric" };
    let options3: Intl.DateTimeFormatOptions = { month: "2-digit" };

    return (
        <div className='k-scheduler-cell k-heading-cell date-header'>
            <span className='weekday'>{props.date.toLocaleDateString("fr", options1)}</span>
            <span className='day'>{props.date.toLocaleDateString("fr", options2)}/{props.date.toLocaleDateString("fr", options3)}</span>
        </div>
    );
};

export const CustomItem = (props: SchedulerItemProps) => {
    return (
        <SchedulerItem
            {...props}
            style={{
                ...props.style,
                background: `${props.dataItem.color}`,
            }}
        />
    )
};

export const ExportedScheduler = (props: any) => {

    const type: string = props.type
    const filters: any[] = props.filters

    const resources = props.resources
    type exportScheme = {
        dates: {
            startDate: Date,
            endDate: Date
        },
        occupations: any[],
        resources: any[],

    }

    const [state, setState] = useState<exportScheme[]>([])
    const [nbOfExportedWeeks, setNbOfExportedWeeks] = useState(5);
    const [result, setResult] = useState<JSX.Element[]>([]);

    const notify = useNotification()

    useEffect(() => {
        init()
    }, [props.date, nbOfExportedWeeks])
    useEffect(() => {
        handleExport()
    }, [state])


    function calcDates() {
        const start = new Date(props.date)
        const end = new Date(start)
        end.setDate(end.getDate() - end.getDay() + 7)
        const dt: Date[][] = [[start, end]];
        for (let i = 1; i < nbOfExportedWeeks; i++) {

            const prevStart = new Date(dt[i - 1][0])
            const prevEnd = new Date(dt[i - 1][1])
            prevStart.setDate(prevStart.getDate() + 7)
            prevEnd.setDate(prevEnd.getDate() + 7)
            dt.push([prevStart, prevEnd])
        }
        return (dt)
    }
    const applyOccupationFilter = (occupation: OccupationDTO) => {
        const result1 = filters[1] && filters[1].requestStatus ? occupation.statut === filters[1].requestStatus?.id : true
        const result2 = filters[1] && filters[1].requestType ? occupation.demande.type === filters[1].requestType?.id : true
        const result3 = filters[0].modality ? occupation.ressource.modaliteId === filters[0].modality.id : true
        const result4 = filters[0].resource ? occupation.ressource.id === filters[0].resource.id : true
        return result1 && result2 && result3 && result4
    };

    async function getResourceOccupations(start: Date, end: Date) {
        const occupationService = new OccupationService()
        return await occupationService.getAllBetween(moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"), null, type)?.then((result: any) => {
            return result.content.filter((occupation: OccupationDTO) => applyOccupationFilter(occupation));
        })
            .catch((error) => notify({ message: error, type: "error" }))

    }
    async function formatResourcesOccupations(occupations: OccupationDTO[]) {
        return occupations?.map((occupation) => formatOccupation(occupation))
    }

    async function init() {
        setResult([])
        const dts = calcDates();
        const proms: Promise<any>[] = [];
        if (resources) {
            for (let dt of dts) {
                proms.push(getResourceOccupations(dt[0], dt[1]).then((occupations: any) => {
                    occupations = occupations.filter((item: OccupationDTO) => applyOccupationFilter(item));
                    return formatResourcesOccupations(occupations).then((formatedOccupations: any) => {
                        const stt: exportScheme = {
                            dates: {
                                startDate: dt[0],
                                endDate: dt[1]
                            },
                            occupations: formatedOccupations,
                            resources: resources.map((item: any) => ({ value: item.id, text: item.nom, dataType: item.getResourceScope(null, null), }))
                        }
                        return stt
                    })
                }))
            }
            await Promise.all(proms).then((result: any) => {
                setState(result)
            })
        }
    }

    const width = (document.getElementById("scheduler-div")?.clientWidth) || 0;

    function handleExport() {
        console.log(type);

        let result = [];
        for (let i = 0; i < state.length; i++) {
            const exportScheme = state[i];
            const maxNumberOfResourcesByPage = 18
            const count = Math.ceil(exportScheme.resources.length / maxNumberOfResourcesByPage)
            for (let j = 0; j < count; j++) {
                const cutResources = exportScheme.resources.slice(0 + maxNumberOfResourcesByPage * j, maxNumberOfResourcesByPage + maxNumberOfResourcesByPage * j)
                const cutOccupations = exportScheme.occupations.filter((occ) => cutResources.find((res) => res.value === occ.occupation.ressource.id))
                result.push(
                    <div
                        id='scheduler-box'
                        className={i || j ? 'break' : ''}
                        style={{ width: width }}
                    >
                        <Scheduler
                            height={952} //@TODO YGR: Taille permettant d'afficher les éléments pour une page A4. EN revanche, si le nbre d'éléments dépasse la hauteur de la page, ils seront tronqués
                            data={cutOccupations}
                            date={exportScheme.dates.startDate}
                            group={{
                                resources: ["Equipements"],
                                orientation: "vertical"
                            }}
                            header={() => null}
                            footer={() => null}
                            resources={[
                                {
                                    name: "Equipements",
                                    data: cutResources,
                                    field: "resourceId",
                                    valueField: "value",
                                    textField: "text",
                                }
                            ]}
                            viewItem={CustomViewItem}
                            item={CustomItem}
                            slot={CustomSlot}
                        >
                            <WeekView
                                dateHeaderCell={CustomDateHeader}
                                slotDivisions={1}
                                slotDuration={1440}
                                step={7}


                            ></WeekView>
                        </Scheduler>
                    </div>
                )
            }

        }
        setResult(result)
        return result;
    }

    const pdfExportStakeholders = useRef<PDFExport>(null);

    function exportSchedulers() {
        if (pdfExportStakeholders.current) {
            pdfExportStakeholders.current.save();
            props.back()
        }
    }


    function handleNumberExported(event: NumericTextBoxChangeEvent) {
        if (event.value) {
            if (event.value > 10 || event.value < 1) {
                alert("Veuillez saisir un nombre entre 1 et 10");
            } else {
                setNbOfExportedWeeks(event.value)
            }

        }
    }

    return (
        <div className='scheduler-export-div'>
            <LocalizationProvider language="fr">
                <IntlProvider locale="fr">
                    <div className='export-overlay'
                    >
                        <div className='export-nav'>
                            <Button onClick={props.back}><i className='icon-arrow-left-medium'></i>Retour</Button>
                            <Button themeColor="primary" onClick={exportSchedulers}>Exporter</Button>
                            <div className='spacer' />
                            <FloatingLabel
                                className='export-nb-input'
                                label='Nombre de semaines'
                                editorId='nbExport'
                                editorValue={nbOfExportedWeeks}
                            >

                                <NumericTextBox
                                    id='nbExport'
                                    defaultValue={nbOfExportedWeeks}
                                    onChange={handleNumberExported} />
                            </FloatingLabel>
                        </div>
                        <PDFExport
                            ref={pdfExportStakeholders}
                            forcePageBreak=".break"
                            paperSize="auto"
                            margin="1cm"
                            landscape={true}
                            fileName={props.fileName}
                            author="Siemens Healthineers Team"
                        >
                            {result.length ?
                                result :
                                <Spinner />}
                        </PDFExport>
                    </div>
                </IntlProvider>
            </LocalizationProvider>
        </div>

    )

}