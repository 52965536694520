import { Link, useNavigate, useLocation } from 'react-router-dom';
import { withRouter } from 'utils/RoutingHelpers';

import { connect } from 'react-redux';

import {
    AppBar, AppBarSection, AppBarSpacer, Avatar, Menu, MenuItem,
} from '@progress/kendo-react-layout';
import Login from 'views/Login/LoginFormView';
import { cloneElement, useEffect, useRef, useState } from 'react';
import { Role } from 'model/RoleEnum';

import { RootState, useAppDispatch } from 'store';
import { setLoggedIn, setUser } from 'store/slices/globalSlice';
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import AuthService from 'services/AuthService';
import Logout from 'views/Login/LogoutView';
import { RequestService } from 'services/RequestService';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import { useNotification } from 'components/Notifications/NotificationProvider';
import ParamService from 'services/ParamService';
import { ParamDTO } from 'model/ParamDTO';
import { modalitiesByType } from 'utils/RequestHelpers';
import { ModalityDTO } from 'model/ModalityDTO';

let itemsInit = [
    { text: 'Planning', selected: true, route: '/' },
    { text: 'Test', selected: false, route: '/test' },
];


const AppRouterContainer = (props: any) => {

    const [items, setItems] = useState(itemsInit);
    let user = props.user
    let isLoggedIn = props.isLoggedIn
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const notify = useNotification()
    const location = useLocation();

    const disableOpenIDAuth = process.env.REACT_APP_DISABLE_OPENID_AUTH

    useEffect(() => {
        if (!user && location.pathname !== "/loggedIn") {
            canIGetUser()
        }
    }, [])

    useEffect((() => {
        if (location.pathname === "/loggedIn") {
            dispatch(setLoggedIn(true))
            navigate("/")
        }
    }), [location.pathname, dispatch, navigate])

    useEffect((() => {
        if (!isLoggedIn && location.pathname !== "/login" && location.pathname !== "/loggedIn") {
            navigate("/loggedOut")
        } else {
            const token = window.localStorage.getItem('token')
            canIGetUser(token)
        }
    }), [isLoggedIn])

    useEffect((() => {
        function getMenuItems() {
            let resultItems = [
                { text: 'Planning', selected: true, route: '/' },
            ];



            if (user) {
                switch (user.role) {
                    case "IC":
                        if (user.modalites.length && user.modalites.filter((item: ModalityDTO) => modalitiesByType.demo.find((item2) => item.nom.toLowerCase() === item2.nom.toLowerCase())).length) {
                            resultItems.push({ text: 'Démonstration', selected: false, route: '/demandes/demo' })
                        }
                        if (user.modalites.length && user.modalites.filter((item: ModalityDTO) => modalitiesByType.loan.find((item2) => item.nom.toLowerCase() === item2.nom.toLowerCase())).length) {
                            resultItems.push({ text: 'Prêt', selected: false, route: '/demandes/pret' })
                        }
                        break;
                    case "GL":
                        resultItems.push(
                            { text: 'Démonstration', selected: false, route: '/demandes/demo' },
                            { text: 'Prêt', selected: false, route: '/demandes/pret' },
                            { text: 'Installation', selected: false, route: '/demandes/install' },
                            { text: 'Retour de Formation', selected: false, route: '/demandes/formation' },
                            { text: 'Maintenance', selected: false, route: '/demandes/maintenance' },
                            { text: 'Absence', selected: false, route: '/demandes/absence' }
                        )
                        break;
                    case "GA":
                        resultItems.push(
                            { text: 'Installation', selected: false, route: '/demandes/install' },
                            { text: 'Retour de Formation', selected: false, route: '/demandes/formation' },
                            { text: 'Absence', selected: false, route: '/demandes/absence' }
                        )
                        break;
                    case "BLM":
                        resultItems.push(
                            { text: 'Prêt', selected: false, route: '/demandes/pret' }
                        )
                        break;
                    case "CS":
                        resultItems.push(
                            { text: 'Maintenance', selected: false, route: '/demandes/maintenance' }
                        )
                        break;
                }
            }

            setItems(resultItems)

        }
        getMenuItems()
        getParams()
    }), [user])


    function canIGetUser(token: string | null = null) {
        if (location.pathname !== "/loggedOut" && location.pathname !== "/login") {
            AuthService.getUserInfo(dispatch, token).then((data: any) => {
                window.localStorage.removeItem('token')
                dispatch(setUser(data))
            }, error => {
                notify({ message: error, type: "error" })
                navigate("/loggedOut");
            })
        }
    }

    function getParams() {
        ParamService.getAllParams()
            .then((result) => {
                setParams(result)
            })
            .catch((error) => {
                notify({ message: error, type: "error" })
            })
    }

    //TODO reduce






    const setSelectedItem = (pathName: string) => {
        let currentPath: any = items.find(item => item.route === pathName);
        if (currentPath && currentPath.text) {
            return currentPath.text;
        } else {
            return "null";
        }
    }
    const logout = () => {
        if (disableOpenIDAuth === "true") {
            dispatch(setLoggedIn(false))
            navigate('/loggedOut')
        } else {
            AuthService.logout()
            dispatch(setLoggedIn(false))
        }
    }

    const onSelect = (event: any) => {
        if (event.item.text !== "Administration")
            navigate(event.item.data.route);
    };

    const selected = setSelectedItem(location.pathname);

    const anchor = useRef<HTMLButtonElement | null>(null);
    const [show, setShow] = useState(false);
    const [visible, setVisible] = useState(false)
    const [dates, setDates] = useState<{ start: Date | null, end: Date | null }>({ start: null, end: null })
    const [params, setParams] = useState<ParamDTO>()

    function handleClick() {
        setShow(!show)
    }

    function toggleDialog() {
        setVisible(!visible)
    }

    function handleExportExcel() {
        if (dates.start && dates.end) {
            const requestService = new RequestService()
            const start = moment(dates.start).format("YYYY-MM-DD")
            const end = moment(dates.end).format("YYYY-MM-DD")
            requestService.getExcelExport(start, end)
                .then((data: any) => {
                    if (data.documentData) {
                        const linkSource = `data:${"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"};base64,${data.documentData}`;
                        const downloadLink = document.createElement("a");
                        downloadLink.href = linkSource;
                        downloadLink.download = data.documentName;
                        downloadLink.click();
                        toggleDialog()
                    } else {
                        notify({ message: "Aucun évènement entre les dates saisies", type: "warning" })
                    }

                })
        }
    }

    function handleChangeStart(event: DatePickerChangeEvent) {
        if (event.value) {
            setDates(({ ...dates }) => ({ ...dates, start: event.value }))
        }
    }
    function handleChangeEnd(event: DatePickerChangeEvent) {
        if (event.value) {
            setDates(({ ...dates }) => ({ ...dates, end: event.value }))
        }
    }


    if (!isLoggedIn || !user) {
        if (location.pathname === "/loggedOut") {
            return (<Logout />)
        }
        if (location.pathname === "/login") {
            return (<Login />)
        }
        return null
    } else {

        return (
            <div className='page-content'>
                {visible && (
                    <Dialog
                        title={"Exporter vers Excel :"}
                        onClose={toggleDialog}
                        width={500}>
                        <h3>Sélectionnez la période</h3>
                        <div className="row add-object">
                            <FloatingLabel
                                label="Date de début"
                                editorId="start"
                                editorValue={dates.start?.toDateString()}
                                className="popup-label required"
                            >
                                <DatePicker
                                    id="start"
                                    placeholder=" "
                                    onChange={(handleChangeStart)}
                                />
                            </FloatingLabel>
                            <FloatingLabel
                                label="Date de fin"
                                editorId="end"
                                editorValue={dates.end?.toDateString()}
                                className="popup-label required"
                            >
                                <DatePicker
                                    id="end"
                                    placeholder=" "
                                    onChange={handleChangeEnd}
                                />
                            </FloatingLabel>
                        </div>
                        <DialogActionsBar layout="end">
                            <Button
                                fillMode="outline"
                                themeColor="base"
                                onClick={toggleDialog}
                            >
                                Annuler
                            </Button>
                            <Button
                                fillMode="solid"
                                themeColor="primary"
                                onClick={handleExportExcel}
                                disabled={!dates.start || !dates.end}
                            >
                                Créer
                            </Button>
                        </DialogActionsBar>
                    </Dialog >
                )}
                <AppBar className='topbar-section'>
                    <AppBarSection className='topbar-logo-section'>
                        <Link to="/">
                            <img src={props.layoutColorMode === 'light' ? '/assets/Siemens_Healthineers_logo.svg' : '/assets/Siemens_Healthineers_logo.svg'} alt="logo" />
                        </Link>
                    </AppBarSection>
                    <AppBarSection className='topbar-app-identifier'>
                        Demo Tool
                    </AppBarSection>
                    <AppBarSpacer />
                    <AppBarSection>
                        <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base info layout-topbar-button" onClick={handleClick} ref={anchor}>
                            <i className="icon-information" />
                        </button>
                        <Popup anchor={anchor.current} show={show} popupClass={"popup-content"}>
                            <span style={{ fontWeight: 800 }}>Un problème ? Une question ?</span>
                            <span>Contactez {params?.contactName} :</span>
                            <span>{params?.contactEmail}</span>
                        </Popup>
                    </AppBarSection>
                    <AppBarSection>
                        <Avatar type="text" >

                            <span>{user.firstName.charAt(0) + user.lastName.charAt(0)}</span>
                        </Avatar>
                    </AppBarSection>
                    <AppBarSection>
                        <div className='col'>
                            <span className='topbar-username'>{user.firstName + " " + user.lastName}</span>
                            <span className='topbar-role'>{Role.get(user.role)}</span>
                        </div>
                    </AppBarSection>
                    <AppBarSection>
                        <Button className="layout-topbar-button" fillMode='flat' onClick={logout}>
                            <i className="icon-log-out" />
                        </Button>
                    </AppBarSection>
                </AppBar >
                <AppBar className='appmenu'>
                    <AppBarSection>
                        <ul>
                            {items.map((item: any) => {
                                return <li key={item.text} className={item.text === selected ? "k-selected" : ""} ><Link to={item.route}>{item.text}</Link></li>
                            })}
                        </ul>
                        {(user.role === 'GL' || user.role === 'GA') &&
                            <Button className='excel-export' onClick={toggleDialog}>
                                <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                                </svg>
                            </Button>
                        }

                    </AppBarSection>
                    {user.role === 'GL'
                        ?
                        <>
                            <div className='divider'></div>
                            <AppBarSection className='admin'>
                                <Menu openOnClick onSelect={onSelect}>
                                    <MenuItem text="Administration">
                                        <MenuItem text="Utilisateurs" data={{ route: "/admin/utilisateurs" }} />
                                        <MenuItem text="Equipements" data={{ route: "/admin/equipements" }} />
                                        <MenuItem text="Intervenants" data={{ route: "/admin/intervenants" }} />
                                        <MenuItem text="Contraintes" data={{ route: "/admin/contraintes" }} />
                                        <MenuItem text="Transporteurs" data={{ route: "/admin/transporteurs" }} />
                                        <MenuItem text="Paramètres" data={{ route: "/admin/parametres" }} />
                                    </MenuItem>
                                </Menu>
                            </AppBarSection>
                        </>

                        : null
                    }
                </AppBar>
                <div className='content'>
                    <div style={{ flexGrow: 1 }}>
                        {cloneElement(props.children, { getUser: props.children.props.getUser })}
                    </div>
                    <div className='footer'>
                        <hr />
                        <ul className='row'>
                            <li><span className='footer-item'>Siemens Healthcare GmbH ©2022</span></li>
                            <li>
                                <a className='footer-item' href="https://ux.siemens-healthineers.com/corporate-info" >Corporate Information</a>
                            </li>
                            <li>
                                <a className='footer-item' href="https://ux.siemens-healthineers.com/siemens-website-privacy-policy" >Privacy Policy</a>
                            </li>
                            <li>
                                <a className='footer-item' href="https://ux.siemens-healthineers.com/cookie" >Cookie Policy</a>
                            </li>
                            <li>
                                <a className='footer-item' href="https://ux.siemens-healthineers.com/terms-of-use" >Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div >
        );
    }
}

const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
    }
}
export default withRouter(connect(mapStateToProps)(AppRouterContainer));
