import { Button } from "@progress/kendo-react-buttons";
import { Card } from "@progress/kendo-react-layout";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    Grid,
    GridCellProps,
    GridColumn as Column,
    GridDataStateChangeEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";

import { ActifCell } from "components/CustomCells/StatusCell";
import { DeleteButton, EditButton } from "components/CustomCells/ActionCell";
import AuthService from "services/AuthService";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { connect } from "react-redux";
import { RootState } from "store";
import { useNotification } from "components/Notifications/NotificationProvider";

const initialClassesState = {
    content: [],
    pageNumber: 1,
    pageSize: 10,
    totalElements: 0,
    totalPages: 1
};

const UserGridView = (props: any) => {

    const navigate = useNavigate();
    const notify = useNotification();
    const initialSort: SortDescriptor[] = [
        {
            field: "nom",
            dir: "asc",
        },
    ];

    const initialPageState = {
        skip: 0,
        take: 10,
        sort: initialSort,
        buttonCount: 10,
        info: true,
        pageSizes: true,
        previousNext: true,
        responsive: true,
    };
    useEffect(() => {
        if (!(props.user && props.user.role === "GL")) {
            navigate("/")
        }
    }, [props.user, navigate])


    const ActionsCell = (props: GridCellProps) => (
        <td>
            <EditButton {...props} action={handleEdit} />
            <DeleteButton {...props} action={handleDelete} message={`Êtes-vous sûr de vouloir supprimer le compte utilisateur de ${props.dataItem.firstName} ${props.dataItem.lastName} ?`}/>
        </td>
    );

    const [pageState, setPageState] = useState(initialPageState);
    const [title, setTitle] = useState("");
    const [addTitle, setAddTitle] = useState("");
    const [classes, setClasses] = useState(initialClassesState)

    let { skip, take, sort } = pageState;

    const getItems = useCallback((pageSize: number | null = null, itemOffset: number | null = null, sortDesciptor: SortDescriptor[] | null = null) => {
        const sortedBy = sortDesciptor?.map((item: SortDescriptor) => item.field + "-" + item.dir).join(",")
        console.log(sortedBy);
        AuthService.getAll(pageSize, itemOffset, sortedBy)
            .then(result => {
                console.log(result);
                setClasses(result)
            });
    },[])

    useEffect(() => {
        const { skip, take, sort } = pageState;
        getItems(take, skip, sort)
    }, [pageState, getItems])

    useEffect((() => {
        setTitle("Gestion des Utilisateurs")
        setAddTitle("Ajouter un utilisateur")
    }), [])


    async function handleDelete(data: any) {
        await AuthService.deleteUser(data.id)
            .then(() => {
                getItems(take, skip, sort);
            })
            .catch((error: any) => {
                notify({ message: error, type: "error" });
            }
            );
    }
    async function handleEdit(data: any) {
        navigate(`/admin/utilisateurs/edit/${data.id}`);        
    }

    function handleNew() {
        navigate("/admin/utilisateurs/create")
    }

    const handlePageChange = (event: GridDataStateChangeEvent) => {
        const { skip, take } = event.dataState;
        setPageState({ ...pageState, skip: skip!, take: take! });

    };

    function handleSortChange(event: GridSortChangeEvent) {
        const sort = event.sort
        setPageState({ ...pageState, sort: sort });
    }


    return (

        <div className='wrapper'>
            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <span className="section-title">{title}</span>

                <Button
                    themeColor={"primary"}
                    onClick={handleNew}
                >
                    {addTitle}
                </Button>
            </div>
            <Card>
                {classes.content.length
                    ?
                    <Grid
                        data={orderBy(classes.content, sort)}

                        sortable={true}
                        sort={sort}
                        onSortChange={handleSortChange}
                        skip={skip}
                        take={take}
                        onDataStateChange={handlePageChange}
                        pageable={true}
                        total={classes.totalElements}
                        pageSize={10}
                    >
                        <Column key="gid" title="GID" field="userName" />,
                        <Column key="lnm" title="Nom" field="lastName" />,
                        <Column key="fnm" title="Prénom" field="firstName" />,
                        <Column key="eml" title="Email" field="email" />,
                        <Column key="rol" title="Rôle" field="role" />,
                        <Column key="sts" title="Statut" field="actif" cell={ActifCell} />,
                        <Column key="act" title="Actions" width={110} cell={ActionsCell} />
                    </Grid>
                    :
                    <div>Aucuns objets trouvés</div>
                }
            </Card>
        </div >
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
    }
}
export default (connect(mapStateToProps)(UserGridView));