import { Button } from "@progress/kendo-react-buttons";
import { Card } from "@progress/kendo-react-layout";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";

import { ActifCell } from "components/CustomCells/StatusCell";
import { DeleteButton, EditButton } from "components/CustomCells/ActionCell";
import { EquipmentService } from "services/EquipmentService";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { connect } from "react-redux";
import { RootState } from "store";
import { useNotification } from "components/Notifications/NotificationProvider";

const EquipmentGridView = (props: any) => {
  const navigate = useNavigate();
  const notify = useNotification();
  const initialSort: SortDescriptor[] = [
    {
      field: "nom",
      dir: "asc",
    },
  ];
  const initialPageState = {
    skip: 0,
    take: 10,
    sort: initialSort,
    buttonCount: 10,
    info: true,
    pageSizes: true,
    previousNext: true,
    responsive: true,
  };
  useEffect(() => {
    if (!(props.user && props.user.role === "GL")) {
      navigate("/");
    }
  }, [props.user, navigate]);

  const initialClassesState = {
    content: [],
    pageNumber: 1,
    pageSize: 10,
    totalElements: 0,
    totalPages: 1,
  };

  const ActionsCell = (props: GridCellProps) => (
    <td>
      <EditButton {...props} action={handleEdit} />
      <DeleteButton
        {...props}
        action={handleDelete}
        message={`Êtes-vous sûr de vouloir supprimer l'équipement ${props.dataItem.nom} ?`}
      />
    </td>
  );

  const EquAssignationCell = (props: GridCellProps) => {
    const value = props.dataItem.finalite;
    let result = "";
    if (value !== null || value !== undefined) {
      if (value === 0) {
        result = "Démonstration";
      } else if (value === 1) {
        result = "Prêt";
      }
    }

    return (
      <td>
        <span className="status-cell">{result}</span>
      </td>
    );
  };

  const [pageState, setPageState] = useState(initialPageState);
  const [title, setTitle] = useState("");
  const [addTitle, setAddTitle] = useState("");
  const [classes, setClasses] = useState(initialClassesState);

  let { skip, take, sort } = pageState;

  const getClasses = useCallback(
    (
      pageSize: number | null = null,
      itemOffset: number | null = null,
      sortDesciptor: SortDescriptor[] | null = null
    ) => {
      const sortedBy = sortDesciptor
        ?.map((item: SortDescriptor) => item.field + "-" + item.dir)
        .join(",");
      console.log(sortedBy);

      const equipmentService = new EquipmentService();
      equipmentService.getAll(pageSize, itemOffset, sortedBy).then((result) => {
        console.log(result);
        setClasses(result);
      });
    },
    []
  );

  useEffect(() => {
    const { skip, take, sort } = pageState;
    getClasses(take, skip, sort);
  }, [pageState, getClasses]);

  useEffect(() => {
    setTitle("Gestion des Equipements");
    setAddTitle("Ajouter un équipement");
  }, []);

  async function handleDelete(data: any) {
    const equipmentService = new EquipmentService();
    await equipmentService
      .delete(data.id)
      .then(() => getClasses(take, skip, sort))
      .catch((error: any) => {
        notify({ message: error, type: "error" });
      });
  }

  async function handleEdit(data: any) {
    navigate(`/admin/equipements/edit/${data.id}`);
  }

  function handleAdd() {
    navigate("/admin/equipements/create");
  }

  const handlePageChange = (event: GridDataStateChangeEvent) => {
    const { skip, take } = event.dataState;
    setPageState({ ...pageState, skip: skip!, take: take! });
  };

  function handleSortChange(event: GridSortChangeEvent) {
    const sort = event.sort;
    setPageState({ ...pageState, sort: sort });
  }

  return (
    <div className="wrapper">
      <div
        className="row"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <span className="section-title">{title}</span>

        <Button themeColor={"primary"} onClick={handleAdd}>
          {addTitle}
        </Button>
      </div>
      <Card>
        {classes.content.length ? (
          <Grid
            data={orderBy(classes.content, sort)}
            sortable={true}
            sort={sort}
            onSortChange={handleSortChange}
            skip={skip}
            take={take}
            onDataStateChange={handlePageChange}
            pageable={true}
            total={classes.totalElements}
            pageSize={10}
          >
            <Column key="nam" title="Libellé" field="nom" />,
            <Column key="snn" title="N° de série" field="numeroDeSerie" />,
            <Column key="mod" title="Modalité" field="modalite.nom" />,
            <Column
              key="pps"
              title="Finalité"
              field="finalite"
              cell={EquAssignationCell}
            />
            ,
            <Column key="atv" title="Statut" field="actif" cell={ActifCell} />,
            <Column key="act" title="Actions" width={110} cell={ActionsCell} />
          </Grid>
        ) : (
          <div>Aucuns objets trouvés</div>
        )}
      </Card>
    </div>
  );
};
const mapStateToProps = function (state: RootState) {
  return {
    user: state.user,
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
  };
};
export default connect(mapStateToProps)(EquipmentGridView);
