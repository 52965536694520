import { Modality, ModalityDTO } from "./ModalityDTO";
import { Request, RequestDTO } from "./RequestDTO";
import { StakeholderDTO } from "./StakeholderDTO";

export interface GetTrainRequestDTO extends RequestDTO {
  intervenants: StakeholderDTO[],
  client: string,
  adresseDeLivraison: {
    rue: string,
    codePostal: string,
    ville: string,
  },
  modalite: ModalityDTO,
  dateApplication1: string,
  dateApplication2: string|null,
  dateApplication3: string|null,
  numeroCommandeFournisseur: string,
}

export interface PostTrainRequestDTO {
  numero: string,
  commentaire: string,
  modaliteId: number,
  adresseDeLivraison: {
    rue: string,
    codePostal: string,
    ville: string,
  },
  client: string,
  intervenantIds: number[],
  dateApplication1: string,
  dateApplication2: string|null,
  dateApplication3: string|null,
  numeroCommandeFournisseur: string,
}

export function GetToPostTrain(data: GetTrainRequestDTO): PostTrainRequestDTO {
  const format: PostTrainRequestDTO = {
    numero: data.numero,
    modaliteId: data.modalite.id,
    commentaire: data.commentaire,
    adresseDeLivraison: {
      rue: data.adresseDeLivraison.rue,
      codePostal: data.adresseDeLivraison.codePostal,
      ville: data.adresseDeLivraison.ville
    },
    client: data.client,
    intervenantIds: data.intervenants?.map((item:any)=>item.id),
    dateApplication1: data.dateApplication1,
    dateApplication2: data.dateApplication2,
    dateApplication3: data.dateApplication3,
    numeroCommandeFournisseur: data.numeroCommandeFournisseur,
  }
  return format;
}

export default class TrainRequest extends Request {
  adresseDeLivraison: { rue: string; codePostal: string; ville: string } = {
    rue: "",
    codePostal: "",
    ville: ""
  }
  client: string = ""
  intervenants = []
  modalite = new Modality();
  dateApplication1 = ""
  dateApplication2 = ""
  dateApplication3 = ""
  numeroCommandeFournisseur = ""
  constructor(dateApplication1 = "") {
    super();
    this.type = 6
    this.dateApplication1 = dateApplication1;
  }
}