import * as React from 'react';


import { Routes, Route } from 'react-router-dom';
import './App.scss';


import AppRouterContainer from './AppRouterContainer';
import Schedule from 'views/Schedule/ScheduleView';
import DemoRequestGridView from 'views/Requests/Demos/DemoRequestGridView';
import DemoRequestFormView from 'views/Requests/Demos/DemoRequestFormView';
import LoanRequestGridView from 'views/Requests/Loans/LoanRequestGridView';
import LoanRequestFormView from 'views/Requests/Loans/LoanRequestFormView';
import InstallRequestGridView from 'views/Requests/Installs/InstallRequestGridView';
import InstallRequestFormView from 'views/Requests/Installs/InstallRequestFormView';
import TrainRequestGridView from 'views/Requests/Trains/TrainRequestGridView';
import TrainRequestFormView from 'views/Requests/Trains/TrainRequestFormView';
import ReviewRequestGridView from 'views/Requests/Reviews/ReviewRequestGridView';
import ReviewRequestFormView from 'views/Requests/Reviews/ReviewRequestFormView';
import VacationRequestGridView from 'views/Requests/Vacations/VacationRequestGridView';
import VacationRequestFormView from 'views/Requests/Vacations/VacationRequestFormView';
//import Login from 'components/Login/Login';
import CarrierGridView from 'views/Admin/Carriers/CarrierGridView';
import ConstraintGridView from 'views/Admin/Constraints/ConstraintGridView';
import EquipmentGridView from 'views/Admin/Equipments/EquipmentGridView';
import StakeholderGridView from 'views/Admin/Stakeholders/StakeholderGridView';
import UserGridView from 'views/Admin/Users/UserGridView';
import UserFormView from 'views/Admin/Users/UserFormView';
import EquipmentFormView from 'views/Admin/Equipments/EquipmentFormView';
import StakeholderFormView from 'views/Admin/Stakeholders/StakeholderFormView';
import Logout from 'views/Login/LogoutView';
import { Outlet } from "react-router";
import {
  IntlProvider,
  load,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/fr/numbers.json";
import caGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/fr/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/fr/timeZoneNames.json";
import esMessages from "./fr.json";
import ParamFormView from 'views/Admin/Param/ParamFormView';

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(esMessages, "fr");

function App() {

  return (

    <LocalizationProvider language="fr">
      <IntlProvider locale="fr">
        <React.Fragment>
          <AppRouterContainer>
            <Routes>
              <Route index element={<Schedule />} />
              <Route path="login" />
              <Route path="loggedIn" />
              <Route path="loggedOut" element={<Logout />} />
              <Route path="demandes/demo" element={<Outlet />}>
                <Route index element={<DemoRequestGridView />} />
                <Route path="create" element={<Outlet />}>
                  <Route index element={<DemoRequestFormView />} />
                  <Route path=":modality" element={<DemoRequestFormView layout="create" />} />
                </Route>
                <Route path="edit/:id" element={<DemoRequestFormView layout="edit" />} />
                <Route path="duplicate/:id" element={<DemoRequestFormView layout="duplicate" />} />
              </Route>
              <Route path="demandes/pret" element={<Outlet />}>
                <Route index element={<LoanRequestGridView />} />
                <Route path="create" element={<Outlet />}>
                  <Route index element={<LoanRequestFormView />} />
                  <Route path=":modality" element={<LoanRequestFormView layout="create" />} />
                </Route>
                <Route path="edit/:id" element={<LoanRequestFormView layout="edit" />} />
                <Route path="duplicate/:id" element={<LoanRequestFormView layout="duplicate" />} />
              </Route>
              <Route path="demandes/install" element={<Outlet />}>
                <Route index element={<InstallRequestGridView />} />
                <Route path="create" element={<Outlet />}>
                  <Route index element={<InstallRequestFormView />} />
                  <Route path=":modality" element={<InstallRequestFormView layout="create" />} />
                </Route>
                <Route path="edit/:id" element={<InstallRequestFormView layout="edit" />} />
              </Route>
              <Route path="demandes/absence" element={<Outlet />}>
                <Route index element={<VacationRequestGridView />} />
                <Route path="create" element={<Outlet />}>
                  <Route index element={<VacationRequestFormView />} />
                  <Route path=":modality" element={<VacationRequestFormView layout="create" />} />
                </Route>
                <Route path="edit/:id" element={<VacationRequestFormView layout="edit" />} />
              </Route>
              <Route path="demandes/maintenance" element={<Outlet />}>
                <Route index element={<ReviewRequestGridView />} />
                <Route path="create" element={<Outlet />}>
                  <Route index element={<ReviewRequestFormView />} />
                  <Route path=":modality" element={<ReviewRequestFormView layout="create" />} />
                </Route>
                <Route path="edit/:id" element={<ReviewRequestFormView layout="edit" />} />
              </Route>
              <Route path="demandes/formation" element={<Outlet />}>
                <Route index element={<TrainRequestGridView />} />
                <Route path="create" element={<Outlet />}>
                  <Route index element={<TrainRequestFormView />} />
                  <Route path=":modality" element={<TrainRequestFormView layout="create" />} />
                </Route>
                <Route path="edit/:id" element={<TrainRequestFormView layout="edit" />} />
              </Route>
              <Route path="admin/utilisateurs" >
                <Route index element={<UserGridView />} />
                <Route path='create' element={<UserFormView layout="create" />} />
                <Route path='edit/:id' element={<UserFormView layout="edit" />} />
              </Route>
              <Route path="admin/equipements" >
                <Route index element={<EquipmentGridView />} />
                <Route path='create' element={<EquipmentFormView layout="create" />} />
                <Route path='edit/:id' element={<EquipmentFormView layout="edit" />} />
              </Route>
              <Route path="admin/intervenants" >
                <Route index element={<StakeholderGridView />} />
                <Route path='create' element={<StakeholderFormView layout="create" />} />
                <Route path='edit/:id' element={<StakeholderFormView layout="edit" />} />
              </Route>
              <Route path="admin/contraintes" >
                <Route index element={<ConstraintGridView />} />
              </Route>
              <Route path="admin/transporteurs" >
                <Route index element={<CarrierGridView />} />
              </Route>
              <Route path="admin/parametres" >
                <Route index element={<ParamFormView />} />
              </Route>
            </Routes>
          </AppRouterContainer>
        </React.Fragment>
      </IntlProvider>
    </LocalizationProvider>

  );
}

export default App;
