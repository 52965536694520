import InitialState, { GlobalUpdateAction } from "../types/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDTO } from "../../model/UserDTO";

const initialState: InitialState = {
  user: null,
  loading: false,
  isLoggedIn: false,
  antiForgeryToken: "",
};

export const globalSlice = createSlice({
  name: GlobalUpdateAction,
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log();

      state.loading = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      console.log();

      state.isLoggedIn = action.payload;
      state.user = null;
    },
    setUser: (state, action: PayloadAction<UserDTO>) => {
      if (state.user) {
        action.payload.token = state.user.token;
      }
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
    setAntiForgeryToken: (state, action: PayloadAction<string>) => {
      state.antiForgeryToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setUser,
  removeUser,
  setLoggedIn,
  setAntiForgeryToken,
} = globalSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default globalSlice.reducer;
