export interface ConstraintDTO {
    nom: string,
    id: number,
}

export interface IConstraint extends ConstraintDTO {
}

export class Constraint implements IConstraint {
    nom: string = "";
    id: number = 0;  
}