import { CompositeFilterDescriptor, isCompositeFilterDescriptor } from "@progress/kendo-data-query";
import { GridColumnMenuCheckboxFilter } from "@progress/kendo-react-grid";
import { SyntheticEvent, useState } from "react";

export const CustomColumnMenuStatusFilter = (props: any) => {
    //console.log(props);
    const [currentFilter, setCurrentFilter] = useState<CompositeFilterDescriptor>(props.filter);


    const dataStatus = props.data.map((item: any) => ({ "statut": item.name }))

    function handleFilterChange(filter: CompositeFilterDescriptor, event: SyntheticEvent) {
        let uniqueFilter: any = filter
        if (isCompositeFilterDescriptor(filter)) {
            let compFilter: CompositeFilterDescriptor = uniqueFilter.filters[0]
            if (compFilter) {
                compFilter.filters = compFilter.filters.slice(-1)
                uniqueFilter.filters[0] = compFilter
            } else {
                uniqueFilter.filters = []
            }
        }
        //console.log("unique Filter : ", uniqueFilter);

        setCurrentFilter(uniqueFilter)

        props.setFilter(uniqueFilter)
    }



    return (
        <div>
            <GridColumnMenuCheckboxFilter
                {...props}
                filter={currentFilter}
                onFilterChange={handleFilterChange}
                data={dataStatus}
                expanded={true}
                uniqueData={true}
            />
        </div>
    );
};