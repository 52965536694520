import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { ConstraintDTO } from "model/ConstraintDTO";
import { useState } from "react";

interface ConstraintFormProps {
    onCancel: () => void;
    onSave: (item: ConstraintDTO) => void;
    item: ConstraintDTO;
}

const ConstraintFormView = (props: ConstraintFormProps) => {
    const [data, setData] = useState(props.item);

    function handleChangeName(event: any) {
        setData({...data, nom: event.value})
    }

    return (<Dialog
        title={props.item.id !== 0 ? `Modifier la contrainte ${props.item.nom}`: 'Nouvelle contrainte de livraison'}
        onClose={props.onCancel}
        width={500}>
        <div className="col edit-object">

            <FloatingLabel
                label="Contrainte"
                editorId="name"
                editorValue={data.nom}
                className="popup-label required"
            >
                <Input
                    id="name"
                    value={data.nom}
                    onChange={handleChangeName}
                />
            </FloatingLabel>
        </div>
        <DialogActionsBar layout="end">
            <Button fillMode="outline"
                themeColor="base"
                onClick={() => props.onCancel()}>
                Annuler
            </Button>
            <Button                             
                fillMode="solid"
                themeColor="primary"
                onClick={() => props.onSave(data)}
                disabled={data.nom === ""}>
                {data.id === 0 ? 'Créer' : 'Enregistrer'}
            </Button>
        </DialogActionsBar>
    </Dialog>)
}

export default ConstraintFormView;