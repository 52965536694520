import { Button } from "@progress/kendo-react-buttons";
import { Card } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    Grid,
    GridCellProps,
    GridColumn as Column,
    GridColumnMenuProps,
    GridDataStateChangeEvent,
    GridFilterChangeEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import { connect } from "react-redux";

import { DemonstrationRequestService } from "services/DemonstrationRequestService";
import DateCell from "components/CustomCells/DateCell";
import { EquipmentCell } from "components/CustomCells/ResourceCell";
import { RequestStatusCell } from "components/CustomCells/StatusCell";
import { CancelButton, DuplicateButton, EditButton } from "components/CustomCells/ActionCell";
import { modalitiesByType } from "utils/RequestHelpers";
import { ModalityService } from "services/ModalityService";
import { ModalityDTO } from "model/ModalityDTO";
import { GetToPostDemo } from "model/DemoRequestDTO";
import { RootState, useAppDispatch } from "store";
import { CompositeFilterDescriptor, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Spinner } from "components/Spinner/Spinner";
import { setLoading } from "store/slices/globalSlice";
import { CustomColumnMenuStatusFilter } from "components/CustomColumnMenus/CustomMenuStatusFilter";
import { StatusDemo } from "model/StatusEnum";
import { ColumnMenu } from "components/CustomColumnMenus/ColumnMenu";
import { useNotification } from "components/Notifications/NotificationProvider";

function getStringFilters(filter: CompositeFilterDescriptor) {
    let result: string = ""

    function getFilter(filters: any): any {
        let result: any[] = []
        for (let filter of filters) {
            if (filter && filter.field) {
                result.push(filter)
            } else {
                const res = getFilter(filter.filters)
                for (let re of res) {
                    result.push(re)
                }

            }
        }
        return result
    }

    if (filter && filter.filters && filter.filters.length) {

        const filters = getFilter(filter.filters)
        result = filters.map((item: any) => {
            if (item.value instanceof Date) {
                return item.field + "|" + moment(item.value).format("DDMMYYYY")
            } else if (item.field === "statut") {
                return item.field + "|" + StatusDemo.find((status: any) => item.value === status.name)?.id
            } else {
                return item.field + "|" + item.value
            }

        }).join(",")
        return result
    }
}



const getRequests = ((pageSize: number | null = null, itemOffset: number | null = null, sortDesciptor: SortDescriptor[] | null = null, filter: CompositeFilterDescriptor) => {
    const sortedBy = sortDesciptor?.map((item: SortDescriptor) => item.field + "-" + item.dir).join(",")
    const requestService = new DemonstrationRequestService();
    const filteredBy = getStringFilters(filter)

    return requestService.getAll(pageSize, itemOffset, sortedBy, filteredBy);

})

const DemoRequestGridView = (props: any) => {

    let type = "demo";
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const notify = useNotification();
    const initialSort: SortDescriptor[] = [
        {
            field: "statut",
            dir: "asc",
        }
    ];
    const initialPageState = {
        skip: 0,
        take: 10,
        buttonCount: 10,
        sort: initialSort,
        info: true,
        pageSizes: true,
        previousNext: true,
        responsive: true,
    };


    const initialRequestsState = {
        content: [],
        pageNumber: 1,
        pageSize: 10,
        totalElements: 0,
        totalPages: 1
    };
    const ActionsCell = (props: GridCellProps) => (
        <td>
            <EditButton {...props} action={handleEdit} />
            <DuplicateButton {...props} action={handleDuplicate} />
            <CancelButton {...props} action={handleDelete} message={`Êtes-vous sûr de vouloir annuler la demande n° ${props.dataItem.numero} ?`} />
        </td>
    );
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
    };

    const ColumnMenuStatusFilter = (props: GridColumnMenuProps) => {
        return (
            <CustomColumnMenuStatusFilter
                data={StatusDemo}
                setFilter={setFilter}
                {...props}
            />
        )
    }

    const [visible, setVisible] = useState(false);
    const [modality, setModality] = useState(null);
    const [modalities, setModalities] = useState<ModalityDTO[]>([]);
    const [pageState, setPageState] = useState(initialPageState);
    const [title, setTitle] = useState("");
    const [requests, setRequests] = useState(initialRequestsState)
    const [filter, setFilter] = useState(initialFilter)
    const [width, setWidth] = useState(window.innerWidth)

    const setWindowDimensions = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, [])

    let { skip, take, sort } = pageState;

    useEffect(() => {
        const { skip, take, sort } = pageState;
        getRequests(take, skip, sort, filter).then((result: any) => {
            setRequests(result);
            dispatch(setLoading(false))
        })
            .catch((error) => notify({ message: error, type: "error" }))


    }, [pageState, filter, dispatch])

    const generalUrl = "/"

    useEffect(() => {
        switch (props.user.role) {
            case "IC":
            case "GL":
                break;
            default:
                navigate(generalUrl)
                break;
        }
    }, [props.user, navigate])

    useEffect((() => {
        let modsByType: any[] = [];
        modsByType = modalitiesByType.demo;
        setTitle("Demandes de Démonstration")

        async function setUserModalities() {
            let userModalities = props.user.modalites
            if (!userModalities.length) {
                const modalityService = new ModalityService()
                await modalityService.getAll().then((result: any) => userModalities = result)
                    .catch((error) => notify({ message: error, type: "error" }))

            }
            setModalities(userModalities.filter((value: ModalityDTO) => { return modsByType.find((item: any) => item.nom.toUpperCase() === value.nom.toUpperCase()) }))
        }
        setUserModalities()
    }), [props.user.modalites])

    async function handleDelete(data: any) {
        const requestService = new DemonstrationRequestService();
        await requestService.get(data.id).then((result: any) => {
            requestService.cancel(data.id, GetToPostDemo(result))
                .then(() => {
                    notify({ message: "Annulation terminée", type: "success" })
                    getRequests(take, skip, sort, filter).then((result: any) => {
                        setRequests(result);
                        dispatch(setLoading(false))
                    })
                        .catch((error) => notify({ message: error, type: "error" }))
                })
                .catch((error) => {
                    notify({ message: error, type: "error" })
                });
        })
            .catch((error) => notify({ message: error, type: "error" }))

    }
    async function handleEdit(data: any) {
        navigate(`/demandes/${type}/edit/${data.id}`);
    }

    async function handleDuplicate(data: any) {
        navigate(`/demandes/${type}/duplicate/${data.id}`);
    }

    const handlePageChange = (event: GridDataStateChangeEvent) => {
        const { skip, take } = event.dataState;
        setPageState({ ...pageState, skip: skip!, take: take! });

    };

    function handleSortChange(event: GridSortChangeEvent) {
        const sort = event.sort

        setPageState({ ...pageState, sort: sort });
    }


    const toggleDialog = () => {
        setVisible(!visible);
    };

    function addRequest() {
        navigate(`/demandes/${type}/create/${modality}`)
    }

    function handleChange(event: any) {
        if (event.value) {
            setModality(event.value.id);
        } else {
            setModality(null);
        }

    }

    function handleAdd() {
        if (modalities.length > 1) {
            toggleDialog()
        } else {
            navigate(`/demandes/${type}/create/${modalities[0].id}`)
        }
    }

    function handleFilterChange(e: GridFilterChangeEvent) {
        console.log(e);
        const result: any = e.filter
        setFilter(result)
    }

    const isOnTablet = width < 800

    return (

        <div className='wrapper' id="wrapper-div">
            {visible && modalities.length > 1 && (
                <Dialog title={"Nouvelle demande de démonstration"} onClose={toggleDialog} width={500}>
                    <div className="col">
                        <span>Veuillez choisir une modalité</span>
                        <FloatingLabel
                            label="Modalité"
                            editorId="modality"
                            editorValue={modality}
                            className="popup-label"
                        >
                            <ComboBox
                                id="modality"
                                data={modalities}
                                dataItemKey="id"
                                textField="nom"
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                    </div>
                    <DialogActionsBar layout="end">
                        <Button
                            fillMode="outline"
                            themeColor="base"
                            onClick={toggleDialog}
                        >
                            Annuler
                        </Button>
                        <Button
                            fillMode="solid"
                            themeColor="primary"
                            onClick={addRequest}
                            disabled={modality === null}
                        >
                            Créer
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <span className="section-title">{title}</span>

                {modalities.length && modalities.filter((item) => modalitiesByType.demo.find((item2) => item.nom.toLowerCase() === item2.nom.toLowerCase())) ?
                    <Button
                        themeColor={"primary"}
                        onClick={handleAdd}
                    >
                        Ajouter une demande
                    </Button>
                    : null
                }
            </div>
            <Card>
                {requests
                    ?
                    <Grid
                        data={orderBy(requests.content, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={handleSortChange}
                        skip={skip}
                        take={take}
                        onDataStateChange={handlePageChange}
                        pageable={true}
                        total={requests.totalElements}
                        pageSize={10}
                        filter={filter}
                        onFilterChange={handleFilterChange}
                        dataItemKey={"id"}
                    >
                        {isOnTablet
                            ? [
                                <Column key="shpd" title="Date de livraison" field="dateDeLivraison" columnMenu={ColumnMenu} width={150} cell={(props: GridCellProps) => {
                                    return DateCell(props.dataItem.dateDeLivraison, "DD/MM/YYYY")
                                }} />,
                                <Column key="cltd" title="Client" field="client" columnMenu={ColumnMenu} />,
                                <Column key="stsd" title="Statut" field="statut" columnMenu={ColumnMenuStatusFilter} width={150} cell={RequestStatusCell} />,
                                <Column key="actd" title="Actions" width={110} cell={ActionsCell} sortable={false} filterable={false} />
                            ]
                            : [
                                <Column key="numd" title="Numéro de demande" columnMenu={ColumnMenu} field="numero" />,
                                <Column key="dted" title="Date de création" field="dateCreation" columnMenu={ColumnMenu} width={150} cell={(props: GridCellProps) => {
                                    return DateCell(props.dataItem.dateCreation, "DD/MM/YYYY")
                                }} />,
                                <Column key="equd" title="Equipement" field="equipementsNom" columnMenu={ColumnMenu} cell={EquipmentCell} />,
                                <Column key="shpd" title="Date de livraison" field="dateDeLivraison" columnMenu={ColumnMenu} width={150} cell={(props: GridCellProps) => {
                                    return DateCell(props.dataItem.dateDeLivraison, "DD/MM/YYYY")
                                }} />,
                                <Column key="wthd" title="Date de retrait" field="dateDeRetrait" columnMenu={ColumnMenu} width={150} cell={(props: GridCellProps) => {
                                    return DateCell(props.dataItem.dateDeRetrait, "DD/MM/YYYY")
                                }} />,
                                <Column key="cltd" title="Client" field="client" columnMenu={ColumnMenu} />,
                                <Column key="ctyd" title="Ville" field="adresseDeLivraisonVille" columnMenu={ColumnMenu} />,
                                <Column key="stsd" title="Statut" field="statut" columnMenu={ColumnMenuStatusFilter} width={150} cell={RequestStatusCell} />,
                                <Column key="actd" title="Actions" width={110} cell={ActionsCell} sortable={false} filterable={false} />
                            ]}

                    </Grid>

                    :
                    <div>Aucunes demandes trouvées</div>
                }
            </Card>
            {props.loading
                ? <Spinner />
                : null
            }
        </div>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(DemoRequestGridView);