import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { TrainRequestService } from "services/TrainRequestService";
import { Form, Field, FormElement, FormRenderProps, } from "@progress/kendo-react-form";

import moment from "moment";

import TrainRequest, { GetTrainRequestDTO, GetToPostTrain } from 'model/TrainRequestDTO';
import { orderNumberValidator, postalCodeValidator, requiredValidator, stakeholdersValidator } from 'utils/FormValidators';
import { Button } from '@progress/kendo-react-buttons';
import { ModalityService } from 'services/ModalityService';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import FieldDateTimePicker from "components/CustomFormFields/FieldDateTimePicker";
import FieldInput from "components/CustomFormFields/FieldInput";
import FieldTextArea from "components/CustomFormFields/FieldTextArea";
import { Spinner } from "components/Spinner/Spinner";
import { FieldStakeHolderInstall } from "components/CustomFormFields/FieldListBox";
import { connect } from "react-redux";
import { RootState } from "store";
import { useNotification } from "components/Notifications/NotificationProvider";
import ResourceErrorPopup from "components/CustomPopups/ResourceErrorPopup";

const TrainRequestFormView = (props: any) => {

    const NAVIGATE_PATH = "/demandes/formation"

    const requestFieldFormat = {
        postalCode: "",
        street: "",
        city: "",
        client: "",
        comment: "",
    }

    const navigate = useNavigate();
    const notify = useNotification();
    const today = new Date();
    let { id } = useParams();
    let { modality: modalityId } = useParams();
    const [request, setRequest] = useState<GetTrainRequestDTO>(new TrainRequest(today.toISOString()));
    const [init, setInit] = useState(requestFieldFormat);
    const user = request?.user;

    const [dates, setDates] = useState<{
        appDate1: Date,
        appDate2: Date | null,
        appDate3: Date | null,
    }>({
        appDate1: today,
        appDate2: null,
        appDate3: null,
    });
    useEffect(() => {
        function handleInit(id: any, modalite: any, layout: any): any {

            async function fetchModalityData(modalityId: number) {
                const modalityService = new ModalityService();
                const modalityResult = await modalityService.get(+modalityId)
                    .catch((error) => notify({ message: error, type: "error" }))

                if (modalityResult) {
                    setRequest(({ ...request }) => ({ ...request, modalite: modalityResult }))
                }
            }
            if (layout === "edit" && id) {

                const requestService = new TrainRequestService();
                requestService.get(+id).then((data: any) => {
                    if (data) {

                        setRequest(data);
                        setDates({
                            appDate1: new Date(data.dateApplication1),
                            appDate2: data.dateApplication2 ? new Date(data.dateApplication2) : null,
                            appDate3: data.dateApplication3 ? new Date(data.dateApplication3) : null,
                        })
                        const res = {
                            postalCode: data.adresseDeLivraison.codePostal,
                            street: data.adresseDeLivraison.rue,
                            city: data.adresseDeLivraison.ville,
                            client: data.client,
                            comment: data.commentaire,
                            dateApplication1: data.dateApplication1,
                            dateApplication2: data.dateApplication2,
                            dateApplication3: data.dateApplication3,
                            cmdNumber: data.numeroCommandeFournisseur,
                        }
                        setInit(res)
                        fetchModalityData(+data.modalite.id)
                    }
                })
                    .catch((error) => notify({ message: error, type: "error" }))

            } else if (props.layout === "create" && modalityId) {
                fetchModalityData(+modalityId);
            }
        }

        handleInit(id, modalityId, props.layout);
    }, [id, modalityId, props.layout])

    const handleSubmit = (dataItem: { [name: string]: any; }) => {

        let result = request;
        result.adresseDeLivraison.codePostal = dataItem.postalCode;
        result.adresseDeLivraison.rue = dataItem.street;
        result.adresseDeLivraison.ville = dataItem.city;
        result.client = dataItem.client;
        result.commentaire = dataItem.comment;
        result.intervenants = dataItem.stakeholders;
        result.dateApplication1 = moment(dataItem.dateApplication1).format('YYYY-MM-DDTHH:mm:ss');
        result.dateApplication2 = dates.appDate2 ? moment(dataItem.dateApplication2).format('YYYY-MM-DDTHH:mm:ss') : null;
        result.dateApplication3 = dates.appDate3 ? moment(dataItem.dateApplication3).format('YYYY-MM-DDTHH:mm:ss') : null;
        result.numeroCommandeFournisseur = dataItem.cmdNumber;
        console.log(result);
        const requestService = new TrainRequestService();
        if (props.layout === "edit" && id) {
            requestService.update(+id, GetToPostTrain(result))
                .then(() => {
                    notify({ message: "Modification terminée", type: "success" })
                    navigate(NAVIGATE_PATH)
                })
                .catch((error) => {
                    if (error.request && error.request.status === 409) {
                        prepareBookedError(error, "modification")
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })
                })
        } else {
            requestService.create(GetToPostTrain(result))
                .then(() => {
                    notify({ message: "Ajout terminé", type: "success" })
                    navigate(NAVIGATE_PATH)
                })
                .catch((error) => {
                    if (error.request && error.request.status === 409) {
                        prepareBookedError(error, "ajout")
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })
                });
        }
    };


    /*const generalUrl = "/demandes/formation"

    useEffect(() => {
        if (props.layout === "edit") {
            switch (props.user.role) {
                case "GA":
                    break;
                case "GL":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else if (props.layout === "create") {
            switch (props.user.role) {
                case "GA":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else {
            navigate(generalUrl)
        }
    }, [props.user, request])*/

    const appDate1Validator = () =>
        !dates.appDate1
            ? "Précisez une date d'application"
            : dates.appDate1 < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : ""
    const appDate2Validator = () =>
        !dates.appDate2
            ? ""
            : dates.appDate2 < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : dates.appDate1 >= dates.appDate2
                    ? "la deuxième date doit être postérieure à la première"
                    : ""
    const appDate3Validator = () =>
        !dates.appDate3
            ? ""
            : dates.appDate3 < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : !dates.appDate2
                    ? "une deuxième date doit être d'abord précisée"
                    : dates.appDate1 >= dates.appDate3
                        ? "la troisième date doit être postérieure à la première"
                        : dates.appDate2 >= dates.appDate3
                            ? "la troisième date doit être postérieure à la deuxième"
                            : ""
    const handleAppDate1Change = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, appDate1: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateApplication1: event.value }));
    }
    const handleAppDate2Change = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, appDate2: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateApplication2: event.value }));
    }
    const handleAppDate3Change = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, appDate3: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateApplication3: event.value }));
    }


    const [visible, setVisible] = useState(false)
    const [bookedError, setBookedError] = useState<any[]>([])
    const [errorType, setErrorType] = useState("")

    function prepareBookedError(error: any, type: string) {
        let result: any[] = []
        if (error.request && error.request.response) {
            try {
                const resultJSON = JSON.parse(error.request.response)
                for (const error of resultJSON.errors) {
                    result.push(error)
                }
                setBookedError(result)
                if (type === "modification")
                    setErrorType("de modifier")
                else if (type === "ajout")
                    setErrorType("d'ajouter")
                else if (type === "validation")
                    setErrorType("de valider")
            } catch (error) {
                notify({ message: error, type: "error" })
            }
        }
    }

    function togglePopupError() {
        setVisible(!visible)
    }


    const anchor = useRef<HTMLButtonElement | null>(null);

    return (
        <>
            <ResourceErrorPopup anchor={anchor.current} togglePopupError={togglePopupError} bookedError={bookedError} type={errorType} visible={visible} />
            <Form
                initialValues={init}
                key={JSON.stringify(init)}
                onSubmit={handleSubmit}
                render={(formRenderProps: FormRenderProps) => (
                    <div className='wrapper'>
                        <FormElement>

                            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <span className="section-title">{props.layout === "edit" ? "Demande de Retour de Formation n°" + request.numero : "Nouvelle demande de Retour de Formation"}</span>
                                <div className="k-form-buttons"
                                    style={{ marginTop: "0" }}
                                >
                                    <Button
                                        name="cancel"
                                        type="button"
                                        fillMode="outline"
                                        onClick={() => { navigate(NAVIGATE_PATH) }}
                                    >
                                        Retour
                                    </Button>
                                    <Button
                                        name="submit"
                                        type={"submit"}
                                        disabled={!formRenderProps.allowSubmit}
                                        themeColor={"primary"}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>
                            <Card>
                                <div>
                                    {user? <div className='form-section'>
                                        <legend className={"k-form-legend"}>Demande faite par</legend>
                                        <span className="modality">{user?.firstName} {user?.lastName} </span>
                                    </div> : null }
                                    <div className='form-section'>
                                        <StackLayout orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"cmdNumber"}
                                                id={"cmdNumber"}
                                                label={"N° Commande fournisseur"}
                                                required={true}
                                                component={FieldInput}
                                                validator={orderNumberValidator}
                                            />
                                            <div></div>
                                        </StackLayout>
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Application</legend>
                                        <StackLayout orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"dateApplication1"}
                                                id={"dateApplication1"}
                                                label={"Date de formation 1"}
                                                required={true}
                                                min={today}
                                                date={dates.appDate1}
                                                component={FieldDateTimePicker}
                                                onChange={handleAppDate1Change}
                                                validator={appDate1Validator}
                                            />
                                            <Field
                                                name={"dateApplication2"}
                                                id={"dateApplication2"}
                                                label={"Date de formation 2"}
                                                min={today}
                                                date={dates.appDate2}
                                                placeholder={"Saisir une date..."}
                                                component={FieldDateTimePicker}
                                                onChange={handleAppDate2Change}
                                                validator={appDate2Validator}

                                            />
                                        </StackLayout>
                                        <StackLayout orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"dateApplication3"}
                                                id={"dateApplication3"}
                                                label={"Date de formation 3"}
                                                min={today}
                                                date={dates.appDate3}
                                                component={FieldDateTimePicker}
                                                onChange={handleAppDate3Change}
                                                validator={appDate3Validator}
                                            />
                                            <div></div>
                                        </StackLayout>
                                        <Field
                                            name={"stakeholders"}
                                            label={"Intervenants"}
                                            required={true}
                                            request={request}
                                            layout={props.layout}
                                            purpose={1}
                                            component={FieldStakeHolderInstall}
                                            validator={stakeholdersValidator}
                                        />
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Client</legend>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"client"}
                                                name={"client"}
                                                label={"Raison Sociale"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <div></div>
                                        </StackLayout>
                                        <span className="k-form-separator"></span>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"street"}
                                                name={"street"}
                                                label={"Rue"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            {window.innerWidth > 900
                                                ? <div></div>
                                                : null
                                            }

                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"postalCode"}
                                                name={"postalCode"}
                                                label={"Code Postal"}
                                                required={true}
                                                component={FieldInput}
                                                validator={postalCodeValidator}
                                            />
                                            <Field
                                                id={"city"}
                                                name={"city"}
                                                label={"Ville"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Commentaire</legend>

                                        <Field
                                            name={"comment"}
                                            value={request.commentaire ? request.commentaire : ""}
                                            rows={4}
                                            component={FieldTextArea}
                                        />
                                    </div>
                                    <div className="k-form-buttons">
                                        <Button
                                            name="cancel"
                                            type="button"
                                            fillMode="outline"
                                            onClick={() => navigate(NAVIGATE_PATH)}
                                        >
                                            Retour
                                        </Button>
                                        <Button
                                            name="submit"
                                            type={"submit"}
                                            disabled={!formRenderProps.allowSubmit}
                                            themeColor={"primary"}
                                        >
                                            Enregistrer
                                        </Button>
                                    </div>

                                </div>
                            </Card>
                        </FormElement>
                    </div>

                )}

            />
            {props.loading
                ? <Spinner />
                : null
            }
        </>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(TrainRequestFormView);