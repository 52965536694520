export const modalitiesByType = {
    demo: [
        { nom: "AT CORINDUS", type: 0 },
        { nom: "AT C-ARMS", type: 1 },
        { nom: "CT", type: 0 },
        { nom: "MI", type: 0 },
        { nom: "MR", type: 0 },
        { nom: "SY", type: 0 },
        { nom: "XP", type: 1 }
    ],
    loan: [
        { nom: "AT C-ARMS", type: 0 },
        { nom: "ANTENNE MR", type: 1 },
        { nom: "XP", type: 1 }
    ],
    install: [
        { nom: "AT C-ARMS", type: 0 }
    ],
    train: [
        { nom: "AT C-ARMS", type: 0 }
    ]
}

export const RequestTypes = [
    {
        name: "Démonstration",
        id: 0
    },
    {
        name: "Prêt",
        id: 1
    },
    {
        name: "Installation",
        id: 2
    },
    {
        name: "Retour de formation",
        id: 3
    },
    
]