import { EquipmentDTO } from "./EquipmentDTO";
import { Request, RequestDTO } from "./RequestDTO";

export interface GetReviewRequestDTO extends RequestDTO {
  equipements: EquipmentDTO[],
  dateDebut: string,
  dateFin: string,

}

export interface PostReviewRequestDTO {
  commentaire: string,
  equipementIds: number[],
  dateDebut: string,
  dateFin: string,
}

export function GetToPostReview(data: GetReviewRequestDTO): PostReviewRequestDTO {

  const format: PostReviewRequestDTO = {
    commentaire: data.commentaire,
    dateDebut: data.dateDebut,
    dateFin: data.dateFin,
    equipementIds: data.equipements?.map((item: any) => item.id),
  }
  return format;
}

export default class ReviewRequest extends Request {
  dateDebut = new Date().toDateString()
  dateFin = new Date().toDateString()
  equipements = []
  commentaire = ""
	finalite = null
	demandeId = 0

  constructor() {
    super();
    this.type = 1
  }
}