import { DateHeaderCellProps } from "@progress/kendo-react-scheduler";

const CustomDateHeader = (props: DateHeaderCellProps) => {
    let options1: Intl.DateTimeFormatOptions = { weekday: "long" };
    let options2: Intl.DateTimeFormatOptions = { day: "numeric" };
    return (
        <div className='k-scheduler-cell k-heading-cell date-header'>
            <span className='weekday'>{props.date.toLocaleDateString("fr", options1)}</span>
            <span className='day'>{props.date.toLocaleDateString("fr", options2)}</span>
        </div>
    );
};

export default CustomDateHeader;