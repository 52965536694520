import { ModalityDTO } from "./ModalityDTO";

export interface UserDTO {
    firstName: string;
    lastName: string;
    userName: string;
    password: string;
    email: string;
    role: string;
    actif: boolean;
    modalites: ModalityDTO[];
    token:string;
    id: number;
}

export interface PostUserDTO {
    firstName: string;
    lastName: string;
    userName: string;
    password: string;
    email: string;
    role: string;
    actif: boolean;
    modaliteIds: number[];
}
export function GetToPostUser(data:UserDTO):PostUserDTO{
    const format:PostUserDTO = {
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.userName,
        password: data.password,
        email: data.email,
        role: data.role,
        actif: data.actif,
        modaliteIds: data.modalites.map((item:any)=>item.id),
    }

    return format
}

export interface IUser extends UserDTO {
}

export class User implements IUser {
    firstName= ""
    lastName= ""
    userName= ""
    password= ""
    email= "";
    role= "";
    actif= false;
    modalites = [];
    token = "";
    id= 0;
    
}