import { useState } from "react";
import { connect } from 'react-redux';

import { Input } from "@progress/kendo-react-inputs";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardImage,
    CardActions,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { RootState } from "store";
import { useNavigate } from "react-router";
import AuthService from "services/AuthService";
import { useNotification } from "components/Notifications/NotificationProvider";

function LoginFormView() {
    const disableOpenIDAuth = process.env.REACT_APP_DISABLE_OPENID_AUTH
    const navigate = useNavigate();
    const notify = useNotification();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function handleLogin() {
        if (disableOpenIDAuth === "true") {
            AuthService.devLogin(username, password)
                .then((user: any) => {
                    window.localStorage.setItem('token', user.token);
                    navigate("/loggedIn")
                })
                .catch((error) => {
                    notify({ message: error, type: "error" })
                })
        } else {
            AuthService.login()
        }
    }
    return (
        <>
            {
                disableOpenIDAuth === "true"
                    ? <div className="loginWrapper">
                        < Card >
                            <CardHeader className="loginHeader">
                                <CardImage
                                    src="../../assets/Siemens_Healthineers_logo.svg"
                                    style={{ height: 50 }}
                                />
                                <CardTitle>Bienvenue sur l'outil DemoTool</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Input
                                    label="Email"
                                    id="email"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.value)}
                                    style={{ width: "100%" }}
                                />

                                <Input
                                    label="Password"
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.value)}
                                    style={{ width: "100%" }}
                                />

                                <Button
                                    icon="k-icon k-i-user"
                                    themeColor={"primary"}
                                    className="w-full"
                                    onClick={handleLogin}
                                >
                                    Sign In
                                </Button>
                            </CardBody>
                        </ Card>
                    </div >

                    :
                    <div className="login">
                        <img src="\assets\Siemens_Healthineers_logo.svg" alt="logo" />
                        <div className="login-wrapper">
                            <Card>
                                <CardHeader style={{ borderStyle: 'hidden' }} >
                                    <h1>Bienvenue !</h1>
                                </CardHeader>
                                <CardBody>
                                    <span>Veuillez vous connecter :</span>
                                </CardBody>
                                <CardActions layout="center">
                                    <Button themeColor="primary" onClick={handleLogin}>Connexion</Button>
                                </CardActions>
                            </Card>
                        </div>
                    </div>
            }
        </>
    )
}

const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        //   loading:state.persistedLoadReducer.loading
    }
}

export default connect(mapStateToProps)(LoginFormView);
