import { UserDTO } from "model/UserDTO";

interface InitialState {
    user: UserDTO|null;
    loading: boolean;
    isLoggedIn: boolean;
    antiForgeryToken: string;
  }
  const GlobalUpdateAction: string = "Global";
  
  export default InitialState;
  export { GlobalUpdateAction};
  