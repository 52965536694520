import axios from 'axios'

const baseUrl = process.env.REACT_APP_USE_LOCAL_API === "true" ? "" : process.env.REACT_APP_API_URL

const defaultOptions = {
    withCredentials: true,
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
};

// Create instance
let instance = axios.create(defaultOptions);



export default instance;
