import { useEffect, useRef, useState, SyntheticEvent } from "react";
import { useNavigate, useParams } from "react-router";
import { InstallRequestService } from "services/InstallRequestService";
import { CarrierService } from "services/CarrierService";
import { Form, Field, FormElement, FormRenderProps, } from "@progress/kendo-react-form";
import moment from "moment";

import InstallRequest, { GetInstallRequestDTO, GetToPostInstall } from 'model/InstallRequestDTO';
import { formationBudgetValidator, orderNumberValidator, phoneValidator, postalCodeValidator, requiredValidator, stakeholdersValidator } from 'utils/FormValidators';
import { Button } from '@progress/kendo-react-buttons';
import { ModalityService } from 'services/ModalityService';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import FieldDateTimePicker from "components/CustomFormFields/FieldDateTimePicker";
import FieldInput from "components/CustomFormFields/FieldInput";
import FieldNumericTextBox from "components/CustomFormFields/FieldNumericTextBox";
import FieldComboBox from "components/CustomFormFields/FieldComboBox";
import FieldUpload from "components/CustomFormFields/FieldUpload";
import FieldTextArea from "components/CustomFormFields/FieldTextArea";
import { Spinner } from "components/Spinner/Spinner";
import { FieldStakeHolderInstall } from "components/CustomFormFields/FieldListBox";
import { Modality, ModalityDTO } from "model/ModalityDTO";
import { CarrierDTO } from "model/CarrierDTO";
import { DocumentDTO } from "model/DocumentDTO";
import { RootState, useAppDispatch } from "store";
import { connect } from "react-redux";
import { useNotification } from "components/Notifications/NotificationProvider";
import { setLoading } from "store/slices/globalSlice";
import ResourceErrorPopup from "components/CustomPopups/ResourceErrorPopup";




const InstallRequestFormView = (props: any) => {

    const requestFieldFormat = {
        postalCode: "",
        street: "",
        city: "",
        client: "",
        comment: "",
        function: "",
        name: "",
        service: "",
        phone: "",
        dateApplication1: new Date(),
        startupDate: new Date(),
        shipping: new Date(),
        carrier: null,
    }

    const navigate = useNavigate();
    const notify = useNotification();
    const dispatch = useAppDispatch();
    let { id } = useParams();
    let { modality } = useParams();
    const [request, setRequest] = useState<GetInstallRequestDTO>(new InstallRequest());
    const [carriers, setCarriers] = useState<CarrierDTO[]>([]);
    const [modalityName, setModality] = useState<ModalityDTO>(new Modality());
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
    const user = request?.user;
    const [init, setInit] = useState<{
        postalCode: string,
        street: string,
        city: string,
        client: string,
        comment: string,
        function: string,
        name: string,
        service: string,
        phone: string,
        dateApplication1: Date,
        startupDate: Date,
        shipping: Date,
        carrier: CarrierDTO | null,
    }>(requestFieldFormat);

    const today = new Date();
    const [dates, setDates] = useState<{
        shipping: Date,
        appDate1: Date,
        appDate2: Date | null,
        appDate3: Date | null,
        startupDate: Date
    }>({
        shipping: today,
        appDate1: today,
        appDate2: null,
        appDate3: null,
        startupDate: today
    });
    useEffect(() => {
        const carrierService = new CarrierService();
        async function fetchCarrierData() {
            const carrierResult = await carrierService.getAllTrans()
                .catch((error) => notify({ message: error, type: "error" }))

            if (carrierResult) {
                setCarriers(carrierResult);
            }
        }
        fetchCarrierData();
        handleInit();
    }, [])


    const handleSubmitForm = (dataItem: { [name: string]: any; }, status: any) => {


        console.log(status.type);

        setDisableSubmit(true)

        let result: GetInstallRequestDTO = {
            intervenants: dataItem.stakeholders,
            client: dataItem.client,
            adresseDeLivraison: {
                rue: dataItem.street,
                codePostal: dataItem.postalCode,
                ville: dataItem.city
            },
            contrainteDeLivraison: request.contrainteDeLivraison,
            contact: {
                nom: dataItem.name,
                service: dataItem.service,
                fonction: dataItem.function,
                telephone: dataItem.phone
            },
            transporteur: dataItem.carrier,
            dateDeLivraison: moment(dataItem.shipping).format('YYYY-MM-DDTHH:mm:ss'),
            commentaire: dataItem.comment,
            modalite: modalityName,
            dateApplication1: moment(dataItem.dateApplication1).format('YYYY-MM-DDTHH:mm:ss'),
            dateApplication2: dates.appDate2 ? moment(dataItem.dateApplication2).format('YYYY-MM-DDTHH:mm:ss') : null,
            dateApplication3: dates.appDate3 ? moment(dataItem.dateApplication3).format('YYYY-MM-DDTHH:mm:ss') : null,
            designationEquipement: dataItem.equipmentName,
            numeroSerieEquipement: dataItem.equipmentNumber,
            budgetDeFormation: dataItem.trainingBudget,
            dateDeMiseEnService: moment(dataItem.startupDate).format('YYYY-MM-DDTHH:mm:ss'),
            commentaireTransporteur: dataItem.carrierComment,
            numeroCommandeFournisseur: dataItem.cmdNumber,
            type: request.type,
            numero: request.numero,
            statut: request.statut,
            id: request.id,
            dateCreation: request.dateCreation,
            demandeDocuments: request.demandeDocuments
        };
        const requestService = new InstallRequestService();
        if (props.layout === "edit" && id) {
            result.statut = status.type === "submit" ? 5 : request.statut
            requestService.update(+id, GetToPostInstall(result))
                .then(() => {
                    // dispatch(setLoading(false))
                    notify({ message: "Modification terminée", type: "success" })
                    navigate("/demandes/install/")
                })
                .catch((error) => {
                    // dispatch(setLoading(false))
                    if (error.request && error.request.status === 409) {
                        prepareBookedError(error, "modification")
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })
                })
        } else {
            console.log(result);

            requestService.create(GetToPostInstall(result))
                .then((res) => {
                    // dispatch(setLoading(false))
                    notify({ message: "Ajout terminé", type: "success" })
                    navigate(`/demandes/install/edit/${res.id}`)
                })
                .catch((error) => {
                    // dispatch(setLoading(false))
                    if (error.request && error.request.status === 409) {
                        prepareBookedError(error, "ajout")
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })

                    setDisableSubmit(false)
                });
        }
    };

    function handleInit(): any {

        async function fetchModalityData(modalityId: number) {
            const modalityService = new ModalityService();
            const modalityResult = await modalityService.get(+modalityId)
                .catch((error) => notify({ message: error, type: "error" }))

            if (modalityResult) {
                setModality(modalityResult);
                setRequest(({ ...request }) => ({ ...request, modalite: modalityResult }))
            }
        }
        if (props.layout === "edit" && id) {

            const requestService = new InstallRequestService();
            requestService.get(+id).then((data: any) => {
                if (data) {
                    console.log(data);

                    setRequest(data);
                    setDates({
                        shipping: new Date(data.dateDeLivraison),
                        appDate1: new Date(data.dateApplication1),
                        appDate2: data.dateApplication2 ? new Date(data.dateApplication2) : null,
                        appDate3: data.dateApplication3 ? new Date(data.dateApplication3) : null,
                        startupDate: new Date(data.dateDeMiseEnService)
                    })
                    const res = {
                        postalCode: data.adresseDeLivraison.codePostal,
                        street: data.adresseDeLivraison.rue,
                        city: data.adresseDeLivraison.ville,
                        client: data.client,
                        carrier: data.transporteur,
                        carrierComment: data.commentaireTransporteur,
                        comment: data.commentaire,
                        function: data.contact.fonction,
                        name: data.contact.nom,
                        service: data.contact.service,
                        phone: data.contact.telephone,
                        shipping: data.dateDeLivraison,
                        dateApplication1: data.dateApplication1,
                        dateApplication2: data.dateApplication2,
                        dateApplication3: data.dateApplication3,
                        startupDate: data.dateDeMiseEnService,
                        equipmentName: data.designationEquipement,
                        equipmentNumber: data.numeroSerieEquipement,
                        trainingBudget: data.budgetDeFormation,
                        cmdNumber: data.numeroCommandeFournisseur,
                        constraint: data.contrainteDeLivraison
                    }
                    console.log(res);

                    setInit(res)
                    fetchModalityData(+data.modalite.id)
                }
            })
                .catch((error) => notify({ message: error, type: "error" }))

        } else if (props.layout === "create" && modality) {
            const modId = +modality;
            fetchModalityData(modId);

        }
    }

    /*const generalUrl = "/demandes/install"
    
    useEffect(() => {
        if (props.layout === "edit") {
            switch (props.user.role) {
                case "GA":
                    break;
                case "GL":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else if (props.layout === "create") {
            switch (props.user.role) {
                case "GA":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else {
            navigate(generalUrl)
        }
    }, [props.user, request])*/

    const appDate1Validator = () =>
        !dates.appDate1
            ? "Précisez une date d'application"
            : dates.appDate1 < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : ""
    const appDate2Validator = () =>
        !dates.appDate2
            ? ""
            : dates.appDate2 < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : dates.appDate1 >= dates.appDate2
                    ? "la deuxième date doit être postérieure à la première"
                    : ""
    const appDate3Validator = () =>
        !dates.appDate3
            ? ""
            : dates.appDate3 < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : !dates.appDate2
                    ? "une deuxième date doit être d'abord précisée"
                    : dates.appDate1 >= dates.appDate3
                        ? "la troisième date doit être postérieure à la première"
                        : dates.appDate2 >= dates.appDate3
                            ? "la troisième date doit être postérieure à la deuxième"
                            : ""
    const startupValidator = (value: any) =>
        !value
            ? "Précisez une date de mise en service"
            : dates.startupDate < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : dates.startupDate >= dates.appDate1
                    ? "la date de mise en service doit être antérieure à la date d'application'"
                    : ""

    const shippingValidator = (value: any) =>
        !value
            ? "Précisez une date de livraison"
            : dates.shipping < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : dates.startupDate <= dates.shipping
                    ? "la date de livraison doit être antérieure à la date de mise en service"
                    : ""

    const handleAppDate1Change = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, appDate1: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateApplication1: event.value }));
    }
    const handleAppDate2Change = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, appDate2: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateApplication2: event.value }));
    }
    const handleAppDate3Change = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, appDate3: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateApplication3: event.value }));
    }
    const handleStartupChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, startupDate: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateDeMiseEnService: event.value }));
    }
    const handleShippingChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, shipping: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateDeLivraison: event.value }));
    }

    function deleteFile(file: DocumentDTO) {
        const result = request.demandeDocuments.filter((item: DocumentDTO) => item !== file);
        setRequest(({ ...request }) => ({ ...request, demandeDocuments: result }));
    }

    async function fileToBase64(file: File) {
        let result = new Uint8Array();
        let prom = new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target!.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsDataURL(file);
        });
        await prom.then((data: any) => {
            result = data.replace(/^data:(.*,)?/, '');
        });
        return result
    }
    async function formatChange(value: any, type: number) {
        let change: DocumentDTO[] = []
        for (let item of value) {
            await fileToBase64(item.getRawFile()).then((result: any) => {
                change.push({
                    documentData: result, documentName: item.name,
                    documentType: type,
                    id: null
                })
            })
                .catch((error) => notify({ message: error, type: "error" }))
        }
        return change
    }

    function handleFilesChange(event: any) {

        if (event.value) {
            formatChange(event.value, 5).then((result: DocumentDTO[]) => {
                // console.log(result)
                setRequest(({ ...request }) => ({ ...request, demandeDocuments: request.demandeDocuments.concat(result) }));
            })
                .catch((error) => notify({ message: error, type: "error" }))

        }

    }
    function handleTransportFilesChange(event: any) {

        if (event.value) {
            formatChange(event.value, 6).then((result: DocumentDTO[]) => {
                // console.log(result)
                setRequest(({ ...request }) => ({ ...request, demandeDocuments: request.demandeDocuments.concat(result) }));
            })
                .catch((error) => notify({ message: error, type: "error" }))

        }

    }


    const [visible, setVisible] = useState(false)
    const [bookedError, setBookedError] = useState<any[]>([])
    const [errorType, setErrorType] = useState("")

    function prepareBookedError(error: any, type: string) {
        let result: any[] = []
        if (error.request && error.request.response) {
            try {
                const resultJSON = JSON.parse(error.request.response)
                for (const error of resultJSON.errors) {
                    result.push(error)
                }
                setBookedError(result)
                if (type === "modification")
                    setErrorType("de modifier")
                else if (type === "ajout")
                    setErrorType("d'ajouter")
                else if (type === "validation")
                    setErrorType("de valider")
            } catch (error) {
                notify({ message: error, type: "error" })
            }
        }
    }

    function togglePopupError() {
        setVisible(!visible)
    }


    const anchor = useRef<HTMLButtonElement | null>(null);

    function evt(event: SyntheticEvent<any>, type: string): SyntheticEvent<any> {
        event.type = type
        return event
    }

    return (
        <>
            <ResourceErrorPopup anchor={anchor.current} togglePopupError={togglePopupError} bookedError={bookedError} type={errorType} visible={visible} />
            <Form
                initialValues={init}
                key={JSON.stringify(init)}
                onSubmit={handleSubmitForm}
                render={(formRenderProps: FormRenderProps) => (
                    <div className='wrapper'>
                        <FormElement>

                            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <span className="section-title">{props.layout === "edit" ? "Demande d'Installation n°" + request.numero : "Nouvelle demande d'Installation"}</span>
                                <div className="k-form-buttons"
                                    style={{ marginTop: "0" }}
                                >
                                    <Button
                                        name="cancel"
                                        type="button"
                                        fillMode="outline"
                                        onClick={() => { navigate("/demandes/install") }}
                                    >
                                        Retour
                                    </Button>
                                    {props.layout === "create" || (props.layout === "edit" && request.statut !== 5) ?
                                        <Button
                                            name="submit"
                                            type={"button"}
                                            onClick={(event) => formRenderProps.onSubmit(evt(event, "draft"))}
                                            disabled={!formRenderProps.allowSubmit || disableSubmit}
                                            themeColor={"primary"}
                                        >
                                            Enregistrer
                                        </Button> : null}
                                    {props.layout === "edit" ?
                                        <Button
                                            name="submit"
                                            type={"submit"}
                                            onClick={(event) => formRenderProps.onSubmit(evt(event, "submit"))}
                                            disabled={!formRenderProps.allowSubmit || disableSubmit}
                                            themeColor={"primary"}
                                        >
                                            Valider
                                        </Button>
                                        : null}
                                </div>
                            </div>
                            <Card>
                                <div>
                                    {user? <div className='form-section'>
                                        <legend className={"k-form-legend"}>Demande faite par</legend>
                                        <span className="modality">{user?.firstName} {user?.lastName} </span>
                                    </div> : null }
                                    <div className='form-section'>
                                        <StackLayout orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"cmdNumber"}
                                                id={"cmdNumber"}
                                                label={"N° Commande fournisseur"}
                                                required={true}
                                                component={FieldInput}
                                                validator={orderNumberValidator}
                                            />
                                            <div></div>
                                        </StackLayout>
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Application</legend>
                                        <StackLayout orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"dateApplication1"}
                                                id={"dateApplication1"}
                                                label={"Date d'application 1"}
                                                required={true}
                                                min={today}
                                                date={dates.appDate1}
                                                component={FieldDateTimePicker}
                                                onChange={handleAppDate1Change}
                                                validator={appDate1Validator}
                                            />
                                            <Field
                                                name={"dateApplication2"}
                                                id={"dateApplication2"}
                                                label={"Date d'application 2"}
                                                min={today}
                                                date={dates.appDate2}
                                                placeholder={"Saisir une date..."}
                                                component={FieldDateTimePicker}
                                                onChange={handleAppDate2Change}
                                                validator={appDate2Validator}

                                            />
                                        </StackLayout>
                                        <StackLayout orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"dateApplication3"}
                                                id={"dateApplication3"}
                                                label={"Date d'application 3"}
                                                min={today}
                                                date={dates.appDate3}
                                                component={FieldDateTimePicker}
                                                onChange={handleAppDate3Change}
                                                validator={appDate3Validator}
                                            />
                                            <div></div>
                                        </StackLayout>
                                        <Field
                                            name={"stakeholders"}
                                            label={"Intervenants"}
                                            required={true}
                                            request={request}
                                            layout={props.layout}
                                            purpose={1}
                                            component={FieldStakeHolderInstall}
                                            validator={stakeholdersValidator}
                                        />
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Equipement</legend>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                id={"equipmentName"}
                                                name={"equipmentName"}
                                                label={"Désignation de l'équipement"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                id={"equipmentNumber"}
                                                name={"equipmentNumber"}
                                                label={"N° de série de l'équipement"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                id={"trainingBudget"}
                                                name={"trainingBudget"}
                                                label={"Budget Formation (nombre de jours)"}
                                                required={true}
                                                component={FieldNumericTextBox}
                                                validator={formationBudgetValidator}
                                            />
                                            <div></div>
                                        </StackLayout>
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Client</legend>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"client"}
                                                name={"client"}
                                                label={"Raison Sociale"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <div></div>
                                        </StackLayout>
                                        <span className="k-form-separator"></span>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"street"}
                                                name={"street"}
                                                label={"Rue"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            {window.innerWidth > 900
                                                ? <div></div>
                                                : null
                                            }

                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"postalCode"}
                                                name={"postalCode"}
                                                label={"Code Postal"}
                                                required={true}
                                                component={FieldInput}
                                                validator={postalCodeValidator}
                                            />
                                            <Field
                                                id={"city"}
                                                name={"city"}
                                                label={"Ville"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                        <span className="k-form-separator"></span>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"name"}
                                                name={"name"}
                                                label={"Nom du contact"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                id={"service"}
                                                name={"service"}
                                                label={"Service"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                id={"function"}
                                                name={"function"}
                                                label={"Fonction"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                id={"phone"}
                                                name={"phone"}
                                                label={"N° de Téléphone"}
                                                type={"tel"}
                                                required={true}
                                                component={FieldInput}
                                                validator={phoneValidator}
                                            />
                                        </StackLayout>
                                        <span className="k-form-separator"></span>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                name={"startupDate"}
                                                id={"startupDate"}
                                                label={"Date de mise en service/CQRI"}
                                                required={true}
                                                min={today}
                                                date={dates.startupDate}
                                                component={FieldDateTimePicker}
                                                onChange={handleStartupChange}
                                                validator={startupValidator}
                                            />
                                            <div></div>
                                        </StackLayout>
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Livraison</legend>

                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}>
                                            <Field
                                                name={"carrier"}
                                                id={"carrier"}
                                                label={"Transporteur"}
                                                required={true}
                                                data={carriers}
                                                component={FieldComboBox}
                                                textField={"nom"}
                                                dataItemKey={"id"}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"shipping"}
                                                id={"shipping"}
                                                label={"Date de livraison"}
                                                required={true}
                                                min={today}
                                                date={dates.shipping}
                                                component={FieldDateTimePicker}
                                                onChange={handleShippingChange}
                                                validator={shippingValidator}
                                            />
                                        </StackLayout>
                                        <Field
                                            name={"carrierComment"}
                                            label={"Commentaire à l'intention du transporteur"}
                                            value={request.commentaireTransporteur ? request.commentaireTransporteur : ""}
                                            rows={4}
                                            component={FieldTextArea}
                                        />
                                        <Field
                                            name={"file"}
                                            multiple={true}
                                            type={"transport"}
                                            data={request.demandeDocuments.filter(document => (document.documentType === 1 || document.documentType === 6))}
                                            deleteFile={deleteFile}
                                            component={FieldUpload}
                                            onChange={handleTransportFilesChange}
                                        />
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Commentaire</legend>

                                        <Field
                                            name={"comment"}
                                            value={request.commentaire ? request.commentaire : ""}
                                            rows={4}
                                            component={FieldTextArea}
                                        />
                                    </div>
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Documents</legend>

                                        <Field
                                            name={"file"}
                                            multiple={true}
                                            data={request.demandeDocuments.filter(document => (document.documentType !== 1 && document.documentType !== 6))}
                                            deleteFile={deleteFile}
                                            component={FieldUpload}
                                            onChange={handleFilesChange}
                                        />
                                    </div>
                                    <div className="k-form-buttons">
                                        <Button
                                            name="cancel"
                                            type="button"
                                            fillMode="outline"
                                            onClick={() => navigate("/demandes/install")}
                                        >
                                            Retour
                                        </Button>
                                        {props.layout === "create" || (props.layout === "edit" && request.statut !== 5) ?
                                            <Button
                                                name="submit"
                                                type={"button"}
                                                onClick={(event) => formRenderProps.onSubmit(evt(event, "draft"))}
                                                disabled={!formRenderProps.allowSubmit || disableSubmit}
                                                themeColor={"primary"}
                                            >
                                                Enregistrer
                                            </Button> : null}
                                        {props.layout === "edit" ?
                                            <Button
                                                name="submit"
                                                type={"submit"}
                                                onClick={(event) => formRenderProps.onSubmit(evt(event, "submit"))}
                                                disabled={!formRenderProps.allowSubmit || disableSubmit}
                                                themeColor={"primary"}
                                            >
                                                Valider
                                            </Button>
                                            : null}
                                    </div>

                                </div>
                            </Card>
                        </FormElement>
                    </div>

                )}

            />
            {props.loading
                ? <Spinner />
                : null
            }
        </>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(InstallRequestFormView);

