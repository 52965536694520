import { DemandeTypes } from 'model/DemandeTypesEnum';
import axios from 'utils/axios';
import { GetReviewRequestDTO, PostReviewRequestDTO } from '../model/ReviewRequestDTO';

export class ReviewRequestService {
    get(id: number) {
        return axios.get(`/api/v1/demandes/revision/` + id)
            .then(response => {

                if (response.data && response.data.type === DemandeTypes.Review.id) {

                    const result: GetReviewRequestDTO = response.data
                    return result

                } else {
                    // Cela ne devrait jamais arriver
                    throw new Error("Mauvais type de demande récupérée")
                }                
            })
            .catch(error => {
                console.log(error)
                throw error;
            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/demandes/revision/` + id)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    getAll(pageSize: any, itemOffset: any, sortedBy: any, filteredBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "statut-asc,dateCreation-asc"
        }
        let filtered="&FilteredBy="
        if (filteredBy) {
            filtered += filteredBy 
        }else{
            filtered = ""
        }
        return axios.get(`/api/v1/demandes/revision?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy+filtered)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    create(data: PostReviewRequestDTO) {
        return axios.post(`/api/v1/demandes/revision`, data)
            .then((response: any) => {
                return response.data
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }



    update(id: number, data: PostReviewRequestDTO) {
        return axios.put(`/api/v1/demandes/revision/` + id, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    cancel(id: number, data: PostReviewRequestDTO) {
        return axios.put(`/api/v1/demandes/revision/` + id + `/cancel`, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }

} 