import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { VacationRequestService } from "services/VacationRequestService";
import { Form, Field, FormElement, FormRenderProps, FieldWrapper, } from "@progress/kendo-react-form";

import moment from "moment";

import { TextArea } from '@progress/kendo-react-inputs';
import VacationRequest, { GetToPostVacation, GetVacationRequestDTO } from 'model/VacationRequestDTO';
import { stakeholdersValidator } from 'utils/FormValidators';
import { Button } from '@progress/kendo-react-buttons';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import FieldDateTimePicker from "components/CustomFormFields/FieldDateTimePicker";
import { FieldStakeholderVacation } from "components/CustomFormFields/FieldListBox";
import { Spinner } from "components/Spinner/Spinner";
import { connect } from "react-redux";
import { RootState } from "store";
import { useNotification } from "components/Notifications/NotificationProvider";
import { Popup } from "@progress/kendo-react-popup";
import RequestErrorPopup from "components/CustomPopups/RequestErrorPopup";
import ResourceErrorPopup from "components/CustomPopups/ResourceErrorPopup";

const NAVIGATE_PATH = "/demandes/absence"

const VacationRequestFormView = (props: any) => {

    const requestFieldFormat = {
        comment: "",
        startDate: null,
        endDate: null,
        stakeholders: [],
    }

    const notify = useNotification();
    const navigate = useNavigate();
    let { id } = useParams();
    const [request, setRequest] = useState<GetVacationRequestDTO>(new VacationRequest());
    const [init, setInit] = useState(requestFieldFormat);
    const user = request?.user;

    const today = new Date();
    const [dates, setDates] = useState({
        startDate: today,
        endDate: today
    });
    useEffect(() => {
        function handleInit(): any {

            if (props.layout === "edit" && id) {
                const requestService = new VacationRequestService();
                requestService.get(+id).then((data: any) => {
                    if (data) {
                        setRequest(data);
                        setDates({
                            startDate: new Date(data.dateDebut),
                            endDate: new Date(data.dateFin),
                        })
                        const res = {
                            comment: data.commentaire,
                            startDate: data.dateDebut,
                            endDate: data.dateFin,
                            stakeholders: data.intervenants,
                        }

                        setInit(res)
                    }
                })
                    .catch((error) => notify({ message: error, type: "error" }))

            }
        }
        handleInit();
    }, [id, props.layout])

    const handleStartDateChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, startDate: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateDebut: event.value }));
    }
    const handleEndDateChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, endDate: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateFin: event.value }));
    }

    const [rqstVisible, setRqstVisible] = useState(false)

    function toggleRqstPopupError() {
        setRqstVisible(!rqstVisible)
    }


    const handleSubmit = (dataItem: { [name: string]: any; }) => {

        let result = request;
        result.commentaire = dataItem.comment;
        result.intervenants = dataItem.stakeholders;

        result.dateDebut = moment(dates.startDate).format('YYYY-MM-DDTHH:mm:ss');
        result.dateFin = moment(dates.endDate).format('YYYY-MM-DDTHH:mm:ss');

        const requestService = new VacationRequestService();
        if (props.layout === "edit" && id) {
            requestService.update(+id, GetToPostVacation(result)).then(() => {
                notify({ message: "Modification terminée", type: "success" })
                navigate(NAVIGATE_PATH)
            }
            ).catch((error) => {
                if (error.request && error.request.status === 409) {
                    prepareBookedError(error, "modification")
                    togglePopupError()
                } else
                    notify({ message: error, type: "error" })
            });
        } else {
            requestService.create(GetToPostVacation(result))
                .then(() => {
                    notify({ message: "Ajout terminé", type: "success" })
                    navigate(NAVIGATE_PATH)

                }).catch((error) => {
                    if (error.request && error.request.status === 451) {
                        prepareRqstBookedError(error)
                        toggleRqstPopupError()
                    } else if (error.request && error.request.status === 409) {
                        prepareBookedError(error, "ajout")
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })
                });
        }
    };
    function prepareRqstBookedError(error: any) {
        let result: any[] = []
        if (error.request && error.request.response) {
            try {
                const resultJSON = JSON.parse(error.request.response)
                for (const error of resultJSON.errors) {
                    result.push(error)
                }
                setRqstBookedError(result)

            } catch (error) {
                notify({ message: error, type: "error" })
            }
        }
    }

    const [rqstBookedError, setRqstBookedError] = useState<any[]>([])



    /*
    const generalUrl = "/demandes/demo"

    useEffect(() => {
        if (props.layout === "edit") {
            switch (props.user.role) {
                case "GA":
                    break;
                case "GL":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else if (props.layout === "create") {
            switch (props.user.role) {
                case "GA":
                    break;
                case "GL":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else {
            navigate(generalUrl)
        }
    }, [props.user, request])*/

    const startDateValidator = () =>
        !dates.startDate
            ? "Précisez une date de début"
            : dates.startDate < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : ""

    const endDateValidator = () =>
        !dates.endDate
            ? "Précisez une date de fin"
            : dates.endDate < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : dates.endDate < dates.startDate
                    ? "la date de fin ne peut pas être avant la date de début"
                    : ""


    const buttonGroup = (formRenderProps: any) => {
        return (
            <>
                <Button
                    name="cancel"
                    type="button"
                    fillMode="outline"
                    onClick={() => { navigate(NAVIGATE_PATH) }}
                >
                    Retour
                </Button>
                <Button
                    name="submit"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    themeColor={"primary"}
                >
                    Enregistrer
                </Button>
            </>

        )
    }
    const rqstAnchor = useRef<HTMLButtonElement | null>(null);


    const [visible, setVisible] = useState(false)
    const [bookedError, setBookedError] = useState<any[]>([])
    const [errorType, setErrorType] = useState("")

    function prepareBookedError(error: any, type: string) {
        let result: any[] = []
        if (error.request && error.request.response) {
            try {
                const resultJSON = JSON.parse(error.request.response)
                for (const error of resultJSON.errors) {
                    result.push(error)
                }
                setBookedError(result)
                if (type === "modification")
                    setErrorType("de modifier")
                else if (type === "ajout")
                    setErrorType("d'ajouter")
                else if (type === "validation")
                    setErrorType("de valider")
            } catch (error) {
                notify({ message: error, type: "error" })
            }
        }
    }

    function togglePopupError() {
        setVisible(!visible)
    }


    const anchor = useRef<HTMLButtonElement | null>(null);

    return (
        <>
            <ResourceErrorPopup anchor={anchor.current} togglePopupError={togglePopupError} bookedError={bookedError} type={errorType} visible={visible} />
            <RequestErrorPopup anchor={rqstAnchor.current} togglePopupError={toggleRqstPopupError} bookedError={rqstBookedError} visible={rqstVisible} />
            <Form
                initialValues={init}
                key={JSON.stringify(init)}
                onSubmit={handleSubmit}
                render={(formRenderProps: FormRenderProps) => (
                    <div className='wrapper'>
                        <FormElement>

                            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <span className="section-title">{props.layout === "edit" ? "Demande d'Absence n°" + request.numero : "Nouvelle demande d'Absence"}</span>
                                <div className="k-form-buttons"
                                    style={{ marginTop: "0" }}
                                >
                                    {buttonGroup(formRenderProps)}
                                </div>
                            </div>
                            <Card>
                                <div>
                                    {user? <div className='form-section'>
                                        <legend className={"k-form-legend"}>Demande faite par</legend>
                                        <span className="modality">{user?.firstName} {user?.lastName} </span>
                                    </div> : null }
                                    <div className='form-section'>
                                        <legend className={"k-form-legend"}>Absence</legend>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                id="startDate"
                                                name={"startDate"}
                                                label={"Date et Heure de Début"}
                                                required={true}
                                                min={today}
                                                date={dates.startDate}
                                                component={FieldDateTimePicker}
                                                onChange={handleStartDateChange}
                                                validator={startDateValidator}

                                            />
                                            <Field
                                                name={"endDate"}
                                                label={"Date et Heure de Fin"}
                                                required={true}
                                                min={dates.startDate}
                                                date={dates.endDate}
                                                component={FieldDateTimePicker}
                                                onChange={handleEndDateChange}
                                                validator={endDateValidator}
                                            />
                                        </StackLayout>

                                        <Field
                                            name={"stakeholders"}
                                            label={"Intervenants"}
                                            required={true}
                                            request={request}
                                            layout={props.layout}
                                            component={FieldStakeholderVacation}
                                            validator={stakeholdersValidator}
                                        />

                                        <FieldWrapper>
                                            <legend className={"k-form-legend"}>Commentaire</legend>
                                            <Field
                                                name={"comment"}
                                                label={"Commentaire"}
                                                value={request.commentaire ? request.commentaire : ""}
                                                rows={4}
                                                component={TextArea}
                                            />
                                        </FieldWrapper>
                                    </div>

                                    <div className="k-form-buttons">
                                        {buttonGroup(formRenderProps)}
                                    </div>

                                </div>
                            </Card>
                        </FormElement>
                    </div>

                )
                }

            />
            {
                props.loading
                    ? <Spinner />
                    : null
            }
        </>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(VacationRequestFormView);