import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form"
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { useCallback } from "react";

const FieldRadioGroup = (fieldRenderProps: FieldRenderProps) => {

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        valid,
        // The input props of the Field.
        value,
        id,
        data,
        multiple,
        label,
        onChange,
        onFocus,
        onBlur,
        ...others
    } = fieldRenderProps;
    

    const showValidationMessage: string | false | null =
        touched && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback((event: any) => {
        onChange({ value: event.value});
    }, [onChange]);
    
    return (
        <FieldWrapper>
            <div onFocus={onFocus} onBlur={onBlur} className={others.required ? "required" : ""}>
                <RadioGroup
                    valid={valid}
                    layout="horizontal"
                    data={data}
                    defaultValue={value}
                    onChange={callback}
                    />
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>

        </FieldWrapper>
    );

}

export default FieldRadioGroup