import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { CarrierDTO } from "model/CarrierDTO";
import { CarrierScopes } from "model/CarrierScopeEnum";
import { useEffect, useState } from "react";

interface CarrierFormProps {
    onCancel: () => void;
    onSave: (item: CarrierDTO) => void;
    item: CarrierDTO;
}

const CarrierFormView = (props: CarrierFormProps) => {
    const [data, setData] = useState(props.item);
    const [carrierScope, setCarrierScope] = useState( {id:-1});

    useEffect(() => {
        setCarrierScope(CarrierScopes.filter(d => d.id === data.defaultDemandeType)[0])
    }, [data.defaultDemandeType])

    function handleChangeName(event: any) {
        setData({...data, nom: event.value})
    }

    function handleChangeMail(event: any) {
        setData({ ...data, email: event.value });
    }

    function handleCarrierScope(event: any) {
        if (event.value) {
            setData({ ...data, defaultDemandeType: event.value.id });
            setCarrierScope(event.value)
        } else {
            setData({ ...data, defaultDemandeType: -1 });
            setCarrierScope({id:-1})
        }
    }
    return (<Dialog
        title={props.item.id !== 0 ? `Modifier le transporteur ${props.item.nom}`: 'Nouveau transporteur'}
        onClose={props.onCancel}
        width={500}>
        <div className="col edit-object">

            <FloatingLabel
                label="Transporteur"
                editorId="name"
                editorValue={data.nom}
                className="popup-label required"
            >
                <Input
                    id="name"
                    value={data.nom}
                    onChange={handleChangeName}
                />
            </FloatingLabel>
            <FloatingLabel
                label="Email"
                editorId="email"
                editorValue={data.email}
                className="popup-label required"
            >
                <Input
                    id="email"
                    value={data.email}
                    onChange={handleChangeMail}
                />
            </FloatingLabel>
            <FloatingLabel>
                <ComboBox
                    label={"Transporteur par défaut du type"}
                    data={CarrierScopes}
                    textField={"name"}
                    dataItemKey={"id"}
                    value={carrierScope}
                    onChange={handleCarrierScope}
                />
            </FloatingLabel>

        </div>
        <DialogActionsBar layout="end">
            <Button fillMode="outline"
                themeColor="base"
                onClick={() => props.onCancel()}>
                Annuler
            </Button>
            <Button                             
                fillMode="solid"
                themeColor="primary"
                onClick={() => props.onSave(data)}
                disabled={data.nom === "" || data.email === ""}>
                {data.id === 0 ? 'Créer' : 'Enregistrer'}
            </Button>
        </DialogActionsBar>
    </Dialog>)
}

export default CarrierFormView;