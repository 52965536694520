import { GridCellProps } from "@progress/kendo-react-grid";

export const RequestStatusCell = (props: GridCellProps) => {
    const value = props.dataItem.statut;
    let result = "";
    let color = "";
    if (value !== null || value !== undefined) {
        switch (value) {
            case 0:
                result = "Enregistrée";
                color = "orange";
                break;
            case 1:
                result = "En Attente BLM";
                color = "orange";
                break;
            case 2:
                result = "En Attente GL";
                color = "orange";
                break;
            case 3:
                result = "Refusée BLM";
                color = "red";
                break;
            case 4:
                result = "Refusée GL";
                color = "red";
                break;
            case 5:
                result = "Validée";
                color = "green";
                break;
            case 6:
                result = "Annulée";
                color = "red";
                break;
        }
    }

    return (
        <td>
            <span className="status-cell" style={{ color: color }}>{result}</span>
        </td>
    );
};

export const ActifCell = (props: GridCellProps) => {
    const value = props.dataItem.actif;
    let result = ""
    if (value !== null || value !== undefined) {

        if (value) {
            result = "Activé";
        }
        else {
            result = "Désactivé";
        }
    }

    return (
        <td>
            <span className="status-cell">{result}</span>
        </td>
    );
};

