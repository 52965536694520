import moment from "moment";

const DateCell = (value: any, format: string) => {
    return (
        <td>
            {!value ? "" : moment(value.toString()).format(format)}
        </td>
    );
};

export default DateCell;