import { GridCellProps } from "@progress/kendo-react-grid";

export const EquipmentCell = (props: GridCellProps) => {
    const value = props.dataItem.equipements;
    let equipments: any[] = [];
    const key = props.dataItem.id + "_equipment"
    if (value) {
        for (let equipment of value) {
            equipments.push(equipment.nom)
        }
    }
    return (
        <td>
            {!value ? "" : equipments.length > 1 ? <div className="resource-cell"><span key={key}>{equipments[0]}</span><span key={key + "-spare"} className="resource-spare">{equipments.slice(1).map((item: any) => <span key={key + "-spare-item"} className="resource-spare-item">{item}</span>)}</span></div> : equipments[0]}
        </td>
    );
};

export const StakeholderCell = (props: GridCellProps) => {
    const value = props.dataItem.intervenants;
    let stakeholders: any[] = [];
    const key = props.dataItem.id + "_stakeholder"
    if (value) {
        for (let stakeholder of value) {
            stakeholders.push(stakeholder.nom)
        }
    }
    return (
        <td>
            {!value ? "" : stakeholders.length > 1 ? <div className="resource-cell"><span key={key}>{stakeholders[0]}</span><span key={key + "-spare"} className="resource-spare">{stakeholders.slice(1).map((item: any) => <span key={key + "-spare-item"} className="resource-spare-item">{item}</span>)}</span></div> : stakeholders[0]}
        </td>
    );
};