import { DemandeTypes } from 'model/DemandeTypesEnum';
import axios from 'utils/axios';
import { GetVacationRequestDTO, PostVacationRequestDTO } from '../model/VacationRequestDTO';

export class VacationRequestService {
    get(id: number) {
        return axios.get(`/api/v1/demandes/conge/` + id)
            .then(response => {

                if (response.data && response.data.type === DemandeTypes.Vacation.id) {

                    const result: GetVacationRequestDTO = response.data
                    return result

                } else {
                    // Cela ne devrait jamais arriver
                    throw new Error("Mauvais type de demande récupérée")
                }                
            })
            .catch(error => {
                console.log(error)
                throw error;
            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/demandes/conge/` + id)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    getAll(pageSize: any, itemOffset: any, sortedBy: any, filteredBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "dateCreation-asc"
        }
        let filtered="&FilteredBy="
        if (filteredBy) {
            filtered += filteredBy 
        }else{
            filtered = ""
        }
        return axios.get(`/api/v1/demandes/conge?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy+filtered)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });

    }
    create(data: PostVacationRequestDTO) {
        return axios.post(`/api/v1/demandes/conge`, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    update(id: number, data: PostVacationRequestDTO) {
        return axios.put(`/api/v1/demandes/conge/` + id, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    cancel(id: number, data: any) {
        return axios.put(`/api/v1/demandes/conge/` + id + `/cancel`, data)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
} 