import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Form, Field, FormElement, FormRenderProps } from "@progress/kendo-react-form";

import { Button } from '@progress/kendo-react-buttons';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import { Spinner } from "components/Spinner/Spinner";
import { ModalityDTO } from "model/ModalityDTO";
import FieldInput from "components/CustomFormFields/FieldInput";
import FieldComboBox from "components/CustomFormFields/FieldComboBox";
import { ModalityService } from "services/ModalityService";
import { Equipment, EquipmentDTO, EquipmentFieldFormat, GetToPostEquipment } from "model/EquipmentDTO";
import { EquipmentService } from "services/EquipmentService";
import FieldDatePicker from "components/CustomFormFields/FieldDatePicker";
import FieldRadioGroup from "components/CustomFormFields/FieldRadioGroup";
import FieldCheckBox from "components/CustomFormFields/FieldCheckBox";
import { radioGroupValidator, requiredValidator } from "utils/FormValidators";
import { adjustForTimezone } from "utils/DateUtils";
import { useNotification } from "components/Notifications/NotificationProvider";

const NAVIGATE_PATH = "/admin/equipements"

const EquipmentFormView = (props: any) => {

    const equipmentFieldFormat = new EquipmentFieldFormat();

    const purposes = [
        {
            label: "Démonstration",
            value: 0,
        },
        {
            label: "Prêt",
            value: 1,
        },
    ];

    const navigate = useNavigate();
    const notify = useNotification();
    let { id } = useParams();
    const [equipment, setEquipment] = useState<EquipmentDTO>(new Equipment());
    const [init, setInit] = useState(equipmentFieldFormat);
    const [loading, setLoading] = useState(false);
    const [modalities, setModalities] = useState<ModalityDTO[]>([]);

    useEffect(() => {
        function handleInit(): any {
            function fetchModality() {
                const modalityService = new ModalityService();
                modalityService.getAll().then((result: any) => {
                    setModalities(result)
                })
            }
    
            if (props.layout === "edit" && id) {
                const equipmentService = new EquipmentService();
                equipmentService.get(+id).then((data: EquipmentDTO) => {
                    if (data) {
                        console.log(data);
    
                        setEquipment(data);
                        const res = {
                            srNum: data.numeroDeSerie,
                            cmdNum: data.numeroDeCommande,
                            factoryDate: data.dateDeSortieUsine? data.dateDeSortieUsine: "" ,
                            active: data.actif,
                            purpose: data.finalite,
                            name: data.nom,
                            modality: data.modalite,
                        }
                        console.log(res);
    
                        setInit(res)
                    }
                });
            }
    
            fetchModality()
        }
        handleInit();
    }, [id, props.layout])

    const handleSubmit = (dataItem: { [name: string]: any; }) => {

        let result = equipment;
        result.actif = dataItem.active;
        result.dateDeSortieUsine = dataItem.factoryDate ? adjustForTimezone(new Date(dataItem.factoryDate)).toISOString() : null;
        result.finalite = dataItem.purpose;
        result.modalite = dataItem.modality;
        result.nom = dataItem.name;
        result.numeroDeCommande = dataItem.cmdNum;
        result.numeroDeSerie = dataItem.srNum;

        console.log(result);

        const equipmentService = new EquipmentService();
        if (props.layout === "edit" && id) {
            toggleLoading()
            equipmentService.put(+id, GetToPostEquipment(result)).then(() =>
                navigate(NAVIGATE_PATH)
            ).catch((error) => {
                notify({ message: error, type: "error" })
            });
        } else {
            toggleLoading()
            equipmentService.post(GetToPostEquipment(result))
                .then(() => {
                    navigate(NAVIGATE_PATH)
                }).catch((error) => {
                    notify({ message: error, type: "error" })
                });
        }
    };

    function toggleLoading() {
        setLoading(!loading);
    }
    const buttonGroup = (formRenderProps: any) => {
        return (
            <>
                <Button
                    name="cancel"
                    type="button"
                    fillMode="outline"
                    onClick={() => { navigate(NAVIGATE_PATH) }}
                >
                    Retour
                </Button>
                <Button
                    name="submit"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    themeColor={"primary"}
                >
                    Enregistrer
                </Button>
            </>

        )
    }

    return (
        <>
            <Form
                initialValues={init}
                key={JSON.stringify(init)}
                onSubmit={handleSubmit}
                render={(formRenderProps: FormRenderProps) => (
                    <div className='wrapper'>
                        <FormElement>

                            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <span className="section-title">{props.layout === "edit" ? "Equipement " + equipment.nom + " SN : " + equipment.numeroDeSerie : "Ajouter un équipement"}</span>
                                <div className="k-form-buttons"
                                    style={{ marginTop: "0" }}
                                >
                                    {buttonGroup(formRenderProps)}
                                </div>
                            </div>
                            <Card>
                                <div>

                                    <div className='form-section'>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name="active"
                                                label={"Equipement actif"}
                                                component={FieldCheckBox}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"name"}
                                                label={"Libellé"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"srNum"}
                                                label={"N° de série"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"cmdNum"}
                                                label={"N° de commande"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"factoryDate"}
                                                label={"Date de sortie d'usine"}
                                                component={FieldDatePicker}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"modality"}
                                                label={"Modalité"}
                                                required={true}
                                                id={"modality"}
                                                data={modalities}
                                                textField="nom"
                                                dataItemKey="id"
                                                component={FieldComboBox}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"purpose"}
                                                label={"Assignation"}
                                                data={purposes}
                                                required={true}
                                                component={FieldRadioGroup}
                                                validator={radioGroupValidator}
                                            />
                                        </StackLayout>
                                    </div>

                                </div>
                            </Card>
                        </FormElement>
                    </div>

                )
                }

            />
            {
                loading
                    ? <Spinner />
                    : null
            }
        </>
    )
}

export default EquipmentFormView;