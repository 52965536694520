import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Error } from "@progress/kendo-react-labels";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form"
import { useCallback } from "react";

const FieldDatePicker = (fieldRenderProps: FieldRenderProps) => {

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        placeholder,
        id,
        disabled,
        optional,
        label,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;


    const showValidationMessage: string | false | null =
        touched && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback((event: DatePickerChangeEvent) => {
        onChange({ value: event.value });

    }, [onChange]);

    return (
        <FieldWrapper >
            <div className={"date-picker k-form-field-wrap" + (others.required ? " required" : "")} onFocus={onFocus} onBlur={onBlur}>

                <DatePicker
                    label={label}
                    disabled={disabled}
                    format={"dd MMM y"}
                    valid={valid}
                    value={value ? new Date(value) : null}
                    placeholder=""
                    onChange={callback}
                    ariaDescribedBy={`${errorId}`}

                />
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>
        </FieldWrapper>

    );

}

export default FieldDatePicker