import { Scope } from 'model/ResourceDTO';
import { Stakeholder } from 'model/StakeholderDTO';
import axios from 'utils/axios';
import { MAX_PAGE_SIZE } from 'services/Constantes';
import { ResourceService } from './ResourceService';

export class StakeholderService extends ResourceService {
    getAll(pageSize: any, itemOffset: any, sortedBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "dateCreation-asc"
        }
        return axios.get(`/api/v1/intervenants?PageOffset=${itemOffset}&PageSize=${pageSize}&SortedBy=${sortedBy}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    getAllResources(filteredBy: any = null) {
        let filtered = "&FilteredBy="
        if (filteredBy) {
            filtered += filteredBy
        } else {
            filtered = ""
        }

        return axios.get(`/api/v1/intervenants?PageSize=${MAX_PAGE_SIZE}&MaxPageSize=${MAX_PAGE_SIZE}&FilterBy=${filtered}`)
            .then(response => {
                return response.data.content.map((d: any) => new Stakeholder(
                    d.id, d.modalite, d.nom, d.perimetre, d.perimetreTemporaire, d.perimetreTemporaireDateDebut, d.perimetreTemporaireDateFin));
            })
            .catch(error => {
                console.log(error)

            });
    }
    post(data: any) {
        return axios.post(`/api/v1/intervenants`, data)
            .catch(error => {
                console.log(error)
            });
    }
    get(id: number) {
        return axios.get(`/api/v1/intervenants/` + id)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)
            });
    }
    getAvailables(modaliteIds: Array<number>, purpose: number, startDate: string, endDate: string) {
        return axios.get(`/api/v1/intervenants/GetAvailables`, { params: { dateDebut: startDate, dateFin: endDate, perimetre: purpose, modaliteIds } })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    put(id: number, data: any) {
        return axios.put(`/api/v1/intervenants/` + id, data)

    }
    patch(id: number, data: any) {
        return axios.patch(`/api/v1/intervenants/` + id, data)
            .catch(error => {
                console.log(error)
            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/intervenants/` + id)
            .catch(error => {
                console.log(error)
            });
    }

    getScopes(): Scope[] {
        return [
            {
                nom: "Demonstration/Pret",
                id: 0,
                couleur: "#F9BFC7"
            },
            {
                nom: "Installation/Formation",
                id: 1,
                couleur: "#BFE6CD"
            }
        ]
    }

    getType(): string {
        return "stakeholder"
    }

    getTypeLabel(): string[] {
        return ["Intervenant", "Intervenants"]
    }

} 